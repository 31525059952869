import { SettlementList } from './SettlementList';
import { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ISettlementUpdateArgs, settlementUpdateMutation } from '../../api/settlementGql';

export interface IVerificationData {
  merchantId: string;
  amount: number;
}

export interface IAdjustmentData {
  merchantId: string;
  amount: number;
  manualTransfer?: number;
  memo: string;
}

export function Settlement() {
  const [verificationDataList, setVerificationDataList] = useState<IVerificationData[]>([]);
  const [adjustmentDataList, setAdjustmentDataList] = useState<IAdjustmentData[]>([]);
  const verificationFileInputRef = useRef<HTMLInputElement>(null);
  const adjustmentFileInputRef = useRef<HTMLInputElement>(null);

  function onChangeVerificationFile(e: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();

    reader.onload = evt => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json<IVerificationData>(ws);
      /* Update state */
      setVerificationDataList(data);
    };

    reader.readAsBinaryString(e.target.files![0]);
  }

  function onChangeAdjustmentFile(e: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();

    reader.onload = evt => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json<IAdjustmentData>(ws);
      /* Update state */
      setAdjustmentDataList(data);
    };

    reader.readAsBinaryString(e.target.files![0]);
  }

  function handleChangeAdjustmentData(merchantId: string, field: 'adjustment', value: number): never;
  function handleChangeAdjustmentData(merchantId: string, field: 'memo', value: string): never;
  function handleChangeAdjustmentData(merchantId: string, field: string, value: number | string) {
    const prev = [...adjustmentDataList];

    let key = prev.findIndex(data => data.merchantId == merchantId);
    let newData = prev[key];

    if (!newData) {
      newData = {
        merchantId: merchantId,
        amount: 0,
        manualTransfer: 0,
        memo: '',
      };

      prev.push(newData);

      key = prev.length - 1;
    }

    if (field == 'adjustment' && typeof value == 'number') {
      prev[key].amount = value;
    }

    if (field == 'memo' && typeof value == 'string') {
      prev[key].memo = value;
    }

    setAdjustmentDataList(prev);
  }

  return (
    <Box width="100%">
      <input
        type="file"
        accept={
          // xlsx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        ref={verificationFileInputRef}
        onChange={onChangeVerificationFile}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        accept={
          // xlsx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        ref={adjustmentFileInputRef}
        onChange={onChangeAdjustmentFile}
        style={{ display: 'none' }}
      />
      <div>
        <div>
          <button onClick={() => verificationFileInputRef.current?.click()}>검증파일 업로드</button>
          <button onClick={() => adjustmentFileInputRef.current?.click()}>보정파일 업로드</button>
          <button>다운로드</button>
        </div>
      </div>
      <SettlementList
        verificationDataList={verificationDataList}
        adjustmentDataList={adjustmentDataList}
        onChangeAdjustmentData={handleChangeAdjustmentData}
      />
    </Box>
  );
}
