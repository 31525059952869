import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import store from 'store';
import { useMutation } from '@apollo/client';
import { extendSessionMutation, logoutMutation } from 'api/authGql';
import { LoginAuthData } from 'api/loginApi';

const authContext = createContext<AuthType>({
  user: undefined,
  updateAuth: async () => {},
  signOut: async () => {},
  checkAuth: async () => {
    return false;
  },
});

interface IAuthUser {
  adminId: string;
  phone: string;
  permission: {
    isAdmin: boolean;
    isCustomerService: boolean;
  };
  groups: [
    {
      id: string;
      code: string;
      name: string;
    }
  ];
}

type AuthType = {
  user?: IAuthUser;
  updateAuth: (variables: LoginAuthData) => Promise<void>;
  signOut: () => Promise<void>;
  checkAuth: () => Promise<boolean>;
};

export function AuthProvider({ children }: { children: any }) {
  const [user, setUser] = useState(store.get('admin'));
  const [doCheckAuth, { loading: checkedAuthLoading }] = useMutation<boolean>(extendSessionMutation);
  const [doSignOut, { loading: signOutLoading }] = useMutation<boolean>(logoutMutation);

  const auth: AuthType = useMemo(() => {
    const updateAuth = async (res: LoginAuthData) => {
      // 로그인 성공 시
      if (res?.id) {
        store.set('admin', res);
        store.set('lastLoggedInUserId', res.id);
        window.hj('identify', null, {
          userId: res.id,
          uid: res.id,
          env: process.env.NODE_CONFIG_ENV || 'local',
        });
        setUser(res);
        return;
      }

      deleteAuth();
      throw new Error('로그인에 실패하였습니다');
    };

    const signOut = async () => {
      if (signOutLoading) {
        return;
      }
      try {
        await doSignOut();
        deleteAuth();
      } catch (e) {
        console.error(e);
      }
    };

    const deleteAuth = () => {
      store.remove('admin');
      setUser(undefined);
    };

    const checkAuth = async () => {
      try {
        const exists = store.get('admin');
        if (!exists) {
          return false;
        }
        const res = await doCheckAuth();
        if (res) {
          window.hj('identify', null, {
            userId: exists.id,
            uid: exists.uid,
            env: process.env.NODE_CONFIG_ENV || 'local',
          });
          return true;
        }
      } catch {}
      deleteAuth();
      return false;
    };

    return {
      user,
      updateAuth,
      signOut,
      deleteAuth,
      checkAuth,
    };
  }, [user, doCheckAuth, doSignOut, signOutLoading]);

  useEffect(() => {
    auth.checkAuth();
  }, [auth]);

  return <authContext.Provider value={auth}>{!checkedAuthLoading && children}</authContext.Provider>;
}

export function useAuth(): AuthType {
  return useContext<AuthType>(authContext);
}
