import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Action = {
  __typename?: 'Action';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Group>>>;
  id: Scalars['ID'];
  menu?: Maybe<Menu>;
  name?: Maybe<Scalars['String']>;
};

export type AddInvitationResult = {
  __typename?: 'AddInvitationResult';
  failedUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Adjustment = {
  amount: Scalars['Int'];
  memo: Scalars['String'];
  merchantId: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  allowedIp?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockedAt?: Maybe<Scalars['Date']>;
  blockedReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Group>>>;
  id: Scalars['ID'];
  lastLoginAt?: Maybe<Scalars['Date']>;
  lastPasswordUpdatedAt?: Maybe<Scalars['Date']>;
  loginFailedCount?: Maybe<Scalars['Int']>;
  merchants?: Maybe<Array<Maybe<Merchant>>>;
  otp?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  passwordReset?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type AdminList = {
  __typename?: 'AdminList';
  list?: Maybe<Array<Maybe<Admin>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Agreement = {
  __typename?: 'Agreement';
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  issueDate?: Maybe<Scalars['Date']>;
  issuedCount?: Maybe<Scalars['Int']>;
  optional?: Maybe<Scalars['Boolean']>;
  previousId?: Maybe<Scalars['ID']>;
  reagree?: Maybe<Scalars['Boolean']>;
  reagreeText?: Maybe<Scalars['String']>;
  sortedIndex?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AgreementList = {
  __typename?: 'AgreementList';
  list?: Maybe<Array<Maybe<Agreement>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Date'];
  expireAt: Scalars['Date'];
  id: Scalars['ID'];
  merchantId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type B2BUser = {
  __typename?: 'B2BUser';
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<B2BUserData>;
  depositCode: Scalars['String'];
  id: Scalars['ID'];
  loginId: Scalars['String'];
  merchant?: Maybe<Merchant>;
  transactionKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type B2BUserData = {
  __typename?: 'B2BUserData';
  permittedMerchantUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BUserList = {
  __typename?: 'B2BUserList';
  list?: Maybe<Array<Maybe<B2BUser>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Bank = {
  __typename?: 'Bank';
  bankCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isRegistrable?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  sortedIndex?: Maybe<Scalars['Int']>;
};

export type BankList = {
  __typename?: 'BankList';
  list?: Maybe<Array<Maybe<Bank>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Banner = {
  __typename?: 'Banner';
  androidImageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
  group?: Maybe<BannerGroup>;
  id: Scalars['ID'];
  iosImageUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  landingUrl?: Maybe<Scalars['String']>;
  sortedIndex?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BannerGroup = {
  __typename?: 'BannerGroup';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BannerGroupList = {
  __typename?: 'BannerGroupList';
  list?: Maybe<Array<Maybe<BannerGroup>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BannerList = {
  __typename?: 'BannerList';
  list?: Maybe<Array<Maybe<Banner>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BannerSortingList = {
  id: Scalars['ID'];
  sortedIndex: Scalars['Int'];
};

export type BoltAirdrop = {
  __typename?: 'BoltAirdrop';
  boltPolicyId: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type BoltDraw = {
  __typename?: 'BoltDraw';
  bettingBlockUntil: Scalars['Date'];
  bettingCountLimit: Scalars['Int'];
  boltCount: Scalars['Int'];
  id: Scalars['Int'];
  probability?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type BoltDrawPromotion = {
  __typename?: 'BoltDrawPromotion';
  bettingCountLimit: Scalars['Int'];
  bettingTimeLimitMin: Scalars['Int'];
  boltDraws?: Maybe<Array<Maybe<BoltDraw>>>;
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  enterableFrom: Scalars['Date'];
  enterableTo: Scalars['Date'];
  id: Scalars['Int'];
  important: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  totalBoltCount?: Maybe<Scalars['Int']>;
  totalUserCount: Scalars['Int'];
  updatedAt: Scalars['Date'];
  visibleFrom: Scalars['Date'];
  visibleTo: Scalars['Date'];
  winnerCount: Scalars['Int'];
};

export type BoltDrawPromotionBoltDrawsArgs = {
  status?: InputMaybe<Scalars['String']>;
};

export type BoltDrawPromotionImage = {
  __typename?: 'BoltDrawPromotionImage';
  boltDrawPromotionId: Scalars['Int'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  priority: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export type BoltDrawPromotionImageList = {
  __typename?: 'BoltDrawPromotionImageList';
  list?: Maybe<Array<Maybe<BoltDrawPromotionImage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoltDrawPromotionList = {
  __typename?: 'BoltDrawPromotionList';
  list?: Maybe<Array<Maybe<BoltDrawPromotion>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoltExclusionRule = {
  __typename?: 'BoltExclusionRule';
  code: Scalars['String'];
  codeType: Scalars['String'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  maxBoltCount?: Maybe<Scalars['Int']>;
  period: Scalars['String'];
  policyType: Scalars['String'];
  receiverType: Scalars['String'];
};

export type BoltExclusionRuleList = {
  __typename?: 'BoltExclusionRuleList';
  list?: Maybe<Array<Maybe<BoltExclusionRule>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoltHistory = {
  __typename?: 'BoltHistory';
  action: Scalars['String'];
  availableBolt?: Maybe<Scalars['Int']>;
  count: Scalars['Int'];
  datetime: Scalars['Date'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type BoltHistoryList = {
  __typename?: 'BoltHistoryList';
  list?: Maybe<Array<Maybe<BoltHistory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoltPolicy = {
  __typename?: 'BoltPolicy';
  airdropUsers?: Maybe<Array<Maybe<BoltAirdrop>>>;
  boltPolicyMerchant: Array<Maybe<BoltPolicyMerchant>>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Date']>;
  expirationRule?: Maybe<ExpirationRule>;
  id: Scalars['ID'];
  issueCountRule?: Maybe<IssueCountRule>;
  periodCapsPerUser?: Maybe<PeriodCapsPerUserRule>;
  receiverType: Scalars['String'];
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type BoltPolicyList = {
  __typename?: 'BoltPolicyList';
  list?: Maybe<Array<Maybe<BoltPolicy>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoltPolicyMerchant = {
  __typename?: 'BoltPolicyMerchant';
  boltPolicyId: Scalars['Int'];
  id: Scalars['Int'];
  merchant: Merchant;
  merchantId: Scalars['String'];
};

export type Boost = {
  __typename?: 'Boost';
  boostCampaignId?: Maybe<Scalars['ID']>;
  boostPromotion?: Maybe<BoostPromotion>;
  boostPromotionId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  id?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  usableFrom?: Maybe<Scalars['Date']>;
  usableTo?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['ID']>;
};

export type BoostBudget = {
  __typename?: 'BoostBudget';
  adSpend?: Maybe<Scalars['BigInt']>;
  amount?: Maybe<Scalars['BigInt']>;
  brandId?: Maybe<Scalars['Int']>;
  budgetTotal?: Maybe<Scalars['BigInt']>;
  chaiCredit?: Maybe<Scalars['BigInt']>;
  dailyCap?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  remainingTotal?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BoostBudgetList = {
  __typename?: 'BoostBudgetList';
  list?: Maybe<Array<Maybe<BoostBudget>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostBudgetStats = {
  __typename?: 'BoostBudgetStats';
  brand?: Maybe<BrandBudgetStats>;
  campaign?: Maybe<CampaignBudgetStats>;
};

export type BoostCampaign = {
  __typename?: 'BoostCampaign';
  adSpendCalculationType?: Maybe<Scalars['String']>;
  adSpendRatio?: Maybe<Scalars['Float']>;
  adSpendUnitPrice?: Maybe<Scalars['BigInt']>;
  adSpendWeight?: Maybe<Scalars['Float']>;
  amountCap?: Maybe<Scalars['BigInt']>;
  animationType?: Maybe<Scalars['String']>;
  attributionUrl?: Maybe<Scalars['String']>;
  boostBudget?: Maybe<BoostBudget>;
  boostBudgetId?: Maybe<Scalars['Int']>;
  boostCampaignContents?: Maybe<Array<Maybe<BoostCampaignContent>>>;
  boostPromotion?: Maybe<BoostPromotion>;
  boostPromotionId?: Maybe<Scalars['Int']>;
  boostUpPolicy?: Maybe<Scalars['Int']>;
  boostUpPolicyName?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  buyableFrom?: Maybe<Scalars['String']>;
  buyableTo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  dailyAmountCap?: Maybe<Scalars['BigInt']>;
  dailyCountCapPerUser?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  importantNotice?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  reasonLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  reservedCampaignBudget?: Maybe<Scalars['BigInt']>;
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  statusLabel?: Maybe<Scalars['String']>;
  targetTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetUserTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  todayReservedCampaignBudget?: Maybe<Scalars['BigInt']>;
  todayUsedCampaignBudget?: Maybe<Scalars['BigInt']>;
  totalCountCapPerUser?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  usableFrom?: Maybe<Scalars['String']>;
  usableTo?: Maybe<Scalars['String']>;
  usedCampaignBudget?: Maybe<Scalars['BigInt']>;
};

export type BoostCampaignAdSpend = {
  __typename?: 'BoostCampaignAdSpend';
  billingType: Scalars['String'];
  boostCampaignId: Scalars['Int'];
  id: Scalars['Int'];
  ratio?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  unitPrice?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type BoostCampaignContent = {
  __typename?: 'BoostCampaignContent';
  brandContent?: Maybe<BrandContent>;
  brandContentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  visibleFrom?: Maybe<Scalars['Date']>;
  visibleTo?: Maybe<Scalars['Date']>;
};

export type BoostCampaignContentInput = {
  brandContentId: Scalars['Int'];
  visibleFrom: Scalars['Date'];
  visibleTo: Scalars['Date'];
};

export type BoostCampaignList = {
  __typename?: 'BoostCampaignList';
  list?: Maybe<Array<Maybe<BoostCampaign>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostCategory = {
  __typename?: 'BoostCategory';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type BoostCategoryList = {
  __typename?: 'BoostCategoryList';
  list?: Maybe<Array<Maybe<BoostCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostCategoryPromotionMapping = {
  __typename?: 'BoostCategoryPromotionMapping';
  boostCategory?: Maybe<BoostCategory>;
  boostCategoryId: Scalars['Int'];
  boostPromotion?: Maybe<BoostPromotion>;
  boostPromotionId: Scalars['Int'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type BoostExclusionRule = {
  __typename?: 'BoostExclusionRule';
  code: Scalars['String'];
  codeType: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type BoostExclusionRuleList = {
  __typename?: 'BoostExclusionRuleList';
  list?: Maybe<Array<Maybe<BoostExclusionRule>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostImportantAdd = {
  __typename?: 'BoostImportantAdd';
  content: Scalars['String'];
  priority: Scalars['Int'];
  table: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type BoostImportantList = {
  __typename?: 'BoostImportantList';
  list?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostImportantUpdate = {
  __typename?: 'BoostImportantUpdate';
  content: Scalars['String'];
  prevSortKey: Scalars['String'];
  priority: Scalars['Int'];
  sortKey: Scalars['String'];
  table: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type BoostList = {
  __typename?: 'BoostList';
  list?: Maybe<Array<Maybe<Boost>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostMerchant = {
  __typename?: 'BoostMerchant';
  boostPromotionId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  merchantId?: Maybe<Scalars['String']>;
};

export type BoostMissionPolicy = {
  __typename?: 'BoostMissionPolicy';
  boltPolicyId?: Maybe<Scalars['Int']>;
  boostCampaignId?: Maybe<Scalars['Int']>;
  buyableToOffsetHour?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  conditionType?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  criteria?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  missionUrl?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  usableToOffsetHour?: Maybe<Scalars['Int']>;
  visibleToOffsetHour?: Maybe<Scalars['Int']>;
};

export type BoostMissionPolicyList = {
  __typename?: 'BoostMissionPolicyList';
  list?: Maybe<Array<Maybe<BoostMissionPolicy>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostPromotion = {
  __typename?: 'BoostPromotion';
  amountCap?: Maybe<Scalars['Int']>;
  androidPackage?: Maybe<Scalars['String']>;
  benefitDescription?: Maybe<Scalars['String']>;
  boltPrice?: Maybe<Scalars['Int']>;
  boostCategories?: Maybe<Array<Maybe<BoostCategory>>>;
  boostMerchants?: Maybe<Array<Maybe<BoostMerchant>>>;
  boostTargetCondition?: Maybe<BoostTargetCondition>;
  boostTargetConditionId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Int']>;
  buyableFrom?: Maybe<Scalars['Date']>;
  buyableTo?: Maybe<Scalars['Date']>;
  countCap?: Maybe<Scalars['Int']>;
  countCapPerUser?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  discountAmountMax?: Maybe<Scalars['String']>;
  discountAmountMin?: Maybe<Scalars['String']>;
  fallbackUrl?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  important?: Maybe<Scalars['String']>;
  iosAppId?: Maybe<Scalars['String']>;
  landingUrl?: Maybe<Scalars['String']>;
  logoImageUrl?: Maybe<Scalars['String']>;
  merchant?: Maybe<Array<Maybe<Merchant>>>;
  name?: Maybe<Scalars['String']>;
  onOffLineStatus?: Maybe<Scalars['String']>;
  promotion?: Maybe<Promotion>;
  promotionId?: Maybe<Scalars['String']>;
  promotionType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subPromotions?: Maybe<Array<Maybe<SubPromotion>>>;
  subTitle?: Maybe<Scalars['String']>;
  taglines?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetMerchantIds?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  tutorialType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typoImageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  usableFrom?: Maybe<Scalars['Date']>;
  usableLocationDescription?: Maybe<Scalars['String']>;
  usableTo?: Maybe<Scalars['Date']>;
  usedBoostAmountSum?: Maybe<Scalars['Int']>;
  visibleFrom?: Maybe<Scalars['Date']>;
  visibleTo?: Maybe<Scalars['Date']>;
};

export type BoostPromotionList = {
  __typename?: 'BoostPromotionList';
  list?: Maybe<Array<Maybe<BoostPromotionListItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostPromotionListItem = {
  __typename?: 'BoostPromotionListItem';
  amountCap?: Maybe<Scalars['Int']>;
  boltPrice?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  countCap?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  onOffLineStatus?: Maybe<Scalars['String']>;
  promotion?: Maybe<Promotion>;
  status?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<Tag>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visibleFrom?: Maybe<Scalars['Date']>;
  visibleTo?: Maybe<Scalars['Date']>;
};

export type BoostReport = {
  __typename?: 'BoostReport';
  boostId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['ID']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type BoostTargetCondition = {
  __typename?: 'BoostTargetCondition';
  condition: Scalars['JSON'];
  conditionText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type BoostTargetConditionList = {
  __typename?: 'BoostTargetConditionList';
  list?: Maybe<Array<Maybe<BoostTargetCondition>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostUp = {
  __typename?: 'BoostUp';
  boltPrice?: Maybe<Scalars['Int']>;
  boostUpSchemePolicies?: Maybe<Array<Maybe<BoostUpScheme>>>;
  budget?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  possibleMoments?: Maybe<Array<Maybe<Scalars['String']>>>;
  startAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  targetTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  visibleFrom?: Maybe<Scalars['Date']>;
  visibleTo?: Maybe<Scalars['Date']>;
};

export type BoostUpList = {
  __typename?: 'BoostUpList';
  list?: Maybe<Array<Maybe<BoostUp>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BoostUpScheme = {
  __typename?: 'BoostUpScheme';
  cashbackAmountMax?: Maybe<Scalars['Int']>;
  cashbackAmountMin?: Maybe<Scalars['Int']>;
  cashbackAmountUnit?: Maybe<Scalars['Int']>;
  discountRate?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Int']>;
  probability?: Maybe<Scalars['Float']>;
  resultType?: Maybe<Scalars['String']>;
};

export type BoostUpSchemeInput = {
  cashbackAmountMax?: InputMaybe<Scalars['Int']>;
  cashbackAmountMin?: InputMaybe<Scalars['Int']>;
  cashbackAmountUnit?: InputMaybe<Scalars['Int']>;
  discountRate?: InputMaybe<Scalars['Float']>;
  level?: InputMaybe<Scalars['Int']>;
  probability?: InputMaybe<Scalars['Float']>;
  resultType?: InputMaybe<Scalars['String']>;
};

export type BoostUpUserHistory = {
  __typename?: 'BoostUpUserHistory';
  boltPrice?: Maybe<Scalars['Int']>;
  boostId?: Maybe<Scalars['Int']>;
  boostUpMoment?: Maybe<Scalars['String']>;
  boostUpPolicyId?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['Int']>;
  cashbackAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  discountRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  resultType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalCashbackAmount?: Maybe<Scalars['Int']>;
  totalDiscountRate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BoostUpUserHistoryList = {
  __typename?: 'BoostUpUserHistoryList';
  list?: Maybe<Array<Maybe<BoostUpUserHistory>>>;
  total?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  androidPackage?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  fallbackUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  iosAppId?: Maybe<Scalars['String']>;
  landingUrl?: Maybe<Scalars['String']>;
  logoImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  typoImageUrl?: Maybe<Scalars['String']>;
};

export type BrandBudget = {
  __typename?: 'BrandBudget';
  adSpend?: Maybe<Scalars['BigInt']>;
  budgetTotal?: Maybe<Scalars['BigInt']>;
  chaiCredit?: Maybe<Scalars['BigInt']>;
  dailyCap?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inUseTotal?: Maybe<Scalars['BigInt']>;
  remainingTotal?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
};

export type BrandBudgetStats = {
  __typename?: 'BrandBudgetStats';
  list?: Maybe<Array<Maybe<BrandBudget>>>;
  total?: Maybe<BrandBudget>;
};

export type BrandContent = {
  __typename?: 'BrandContent';
  bucketFileName?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  sourceFileName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  urls?: Maybe<Array<Maybe<BrandContentUrl>>>;
  visible?: Maybe<Scalars['String']>;
};

export type BrandContentList = {
  __typename?: 'BrandContentList';
  list?: Maybe<Array<Maybe<BrandContent>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BrandContentUrl = {
  __typename?: 'BrandContentURL';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BrandList = {
  __typename?: 'BrandList';
  list?: Maybe<Array<Maybe<Brand>>>;
  total?: Maybe<Scalars['Int']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CampaignBudget = {
  __typename?: 'CampaignBudget';
  adSpendInUse?: Maybe<Scalars['BigInt']>;
  chaiCreditInUse?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inUseTotal?: Maybe<Scalars['BigInt']>;
  paidTotal?: Maybe<Scalars['BigInt']>;
  remainingTotal?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
  toBePaidTotal?: Maybe<Scalars['BigInt']>;
  type?: Maybe<Scalars['String']>;
};

export type CampaignBudgetStats = {
  __typename?: 'CampaignBudgetStats';
  list?: Maybe<Array<Maybe<CampaignBudget>>>;
  total?: Maybe<CampaignBudget>;
};

export type CardProduct = {
  __typename?: 'CardProduct';
  annualFee?: Maybe<Scalars['Int']>;
  benefitImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  descriptionImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  emphasizeColor1?: Maybe<Scalars['String']>;
  emphasizeColor2?: Maybe<Scalars['String']>;
  emphasizeText?: Maybe<Scalars['String']>;
  externalIssueUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDomestic?: Maybe<Scalars['Boolean']>;
  issueBoltPrice?: Maybe<Scalars['Int']>;
  issueTarget?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  logoImageUrl?: Maybe<Scalars['String']>;
  maxEarlyBirdCount?: Maybe<Scalars['Int']>;
  maxMonthlyBenefitAmount?: Maybe<Scalars['Int']>;
  productCode?: Maybe<Scalars['String']>;
  reissueBoltPrice?: Maybe<Scalars['Int']>;
  resourceType?: Maybe<Scalars['String']>;
  shareableUrl?: Maybe<Scalars['String']>;
  sortedIndex?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDescription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CardProductList = {
  __typename?: 'CardProductList';
  list?: Maybe<Array<Maybe<CardProduct>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CardServerInspectResult = {
  __typename?: 'CardServerInspectResult';
  code?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChargeInData = {
  __typename?: 'ChargeInData';
  paymentId?: Maybe<Scalars['String']>;
  pgResult?: Maybe<Scalars['JSON']>;
};

export type ChartData = {
  __typename?: 'ChartData';
  canceledAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  merchantCouponDiscountAmount?: Maybe<Scalars['Float']>;
  paymentCountByTimes?: Maybe<Scalars['JSON']>;
  payments?: Maybe<Scalars['JSON']>;
  purchasedAmount?: Maybe<Scalars['Float']>;
  registeredUsers?: Maybe<Scalars['JSON']>;
  top20Products?: Maybe<Scalars['JSON']>;
  transactionVolume?: Maybe<Scalars['Float']>;
  uniqueCountOfPurchasedUser?: Maybe<Scalars['Float']>;
};

export type ChartTimeIntervals = {
  paymentCountByTimes?: InputMaybe<Scalars['String']>;
  payments?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<Scalars['String']>;
};

export type ContactInvitation = {
  __typename?: 'ContactInvitation';
  createdAt?: Maybe<Scalars['Date']>;
  inviteePhone?: Maybe<Scalars['String']>;
  inviteeUserId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ContactInvitationList = {
  __typename?: 'ContactInvitationList';
  list?: Maybe<Array<Maybe<ContactInvitation>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContentUrl = {
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  autoExtension: Scalars['String'];
  documentUrl?: Maybe<Scalars['String']>;
  fee: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['ID'];
  includedVat?: Maybe<Scalars['Boolean']>;
  settlementCycle: Scalars['Int'];
  settlementProvideCycle: Scalars['String'];
  termEndAt: Scalars['Date'];
  termStartAt: Scalars['Date'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ContractList = {
  __typename?: 'ContractList';
  list?: Maybe<Array<Maybe<Contract>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  benefitType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  isSelectable?: Maybe<Scalars['Boolean']>;
  issueType?: Maybe<Scalars['String']>;
  maxDiscountAmount?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CouponCounts = {
  __typename?: 'CouponCounts';
  disabled?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  issued?: Maybe<Scalars['Int']>;
  notSelectable?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Int']>;
};

export type CouponIssueResult = {
  __typename?: 'CouponIssueResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  successIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CouponList = {
  __typename?: 'CouponList';
  list?: Maybe<Array<Maybe<Coupon>>>;
  total?: Maybe<Scalars['Int']>;
};

export type DateHistory = {
  __typename?: 'DateHistory';
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type DelayedCashback = {
  __typename?: 'DelayedCashback';
  actionType?: Maybe<Scalars['String']>;
  billingDelta?: Maybe<Scalars['Int']>;
  boostId?: Maybe<Scalars['Int']>;
  cashbackDelta?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isPublicTransportation?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['String']>;
  promotionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['Date']>;
};

export type DelayedCashbackList = {
  __typename?: 'DelayedCashbackList';
  list?: Maybe<Array<Maybe<DelayedCashback>>>;
  total?: Maybe<Scalars['Int']>;
};

export type DeleteHistory = {
  __typename?: 'DeleteHistory';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};

export type DiscountSplitCondition = {
  __typename?: 'DiscountSplitCondition';
  merchantRatio: Scalars['Int'];
  priceMax: Scalars['Int'];
  priceMin: Scalars['Int'];
};

export type DiscountSplitConditionInput = {
  merchantRatio: Scalars['Int'];
  priceMax: Scalars['Int'];
  priceMin: Scalars['Int'];
};

export type ExchangePropertyRule = {
  __typename?: 'ExchangePropertyRule';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ruleCodeType?: Maybe<ExchangeRuleCodeType>;
};

export type ExchangePropertyRuleList = {
  __typename?: 'ExchangePropertyRuleList';
  list?: Maybe<Array<Maybe<ExchangePropertyRule>>>;
  total?: Maybe<Scalars['Int']>;
};

export enum ExchangeRuleCodeType {
  BusinessCode = 'businessCode',
  BusinessName = 'businessName',
  MerchantId = 'merchantId',
  MerchantName = 'merchantName',
}

export type ExpirationRule = {
  __typename?: 'ExpirationRule';
  absoluteTime?: Maybe<Scalars['Date']>;
  relativeTime?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type ExpirationRuleInput = {
  absoluteTime?: InputMaybe<Scalars['Date']>;
  relativeTime?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type Faq = {
  __typename?: 'Faq';
  author: Scalars['String'];
  category: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type FaqList = {
  __typename?: 'FaqList';
  list?: Maybe<Array<Maybe<Faq>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Group = {
  __typename?: 'Group';
  actions?: Maybe<Array<Maybe<Action>>>;
  admins?: Maybe<Array<Maybe<Admin>>>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  menus?: Maybe<Array<Maybe<Menu>>>;
  name: Scalars['String'];
};

export type I18n = {
  __typename?: 'I18n';
  columnName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type IMetroCard = {
  __typename?: 'IMetroCard';
  activatedAt?: Maybe<Scalars['String']>;
  balance: Scalars['Int'];
  batchStatus: Scalars['String'];
  depositAmount: Scalars['Int'];
  expectedAmount: Scalars['Int'];
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type IMetroCardResponse = {
  __typename?: 'IMetroCardResponse';
  cardList?: Maybe<Array<Maybe<IMetroCard>>>;
};

export type IMetroHistory = {
  __typename?: 'IMetroHistory';
  amount: Scalars['Int'];
  balance: Scalars['Int'];
  cardUserId: Scalars['String'];
  createdAt: Scalars['String'];
  data: IMetroTransactionData;
  id: Scalars['ID'];
  metroCardId: Scalars['Int'];
  type: Scalars['String'];
  usedAt: Scalars['String'];
};

export type IMetroHistoryResponse = {
  __typename?: 'IMetroHistoryResponse';
  history?: Maybe<Array<Maybe<IMetroHistory>>>;
};

export type IMetroTransactionData = {
  __typename?: 'IMetroTransactionData';
  busNumber?: Maybe<Scalars['String']>;
  busStopName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  isTransfer?: Maybe<Scalars['Boolean']>;
  person?: Maybe<Scalars['Int']>;
  useType: Scalars['String'];
};

export type IssueCountRule = {
  __typename?: 'IssueCountRule';
  count?: Maybe<Scalars['Int']>;
  range?: Maybe<Array<Maybe<IssueCountRuleRange>>>;
  type: Scalars['String'];
};

export type IssueCountRuleRange = {
  __typename?: 'IssueCountRuleRange';
  count: Scalars['Int'];
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type IssueCountRuleRangeInput = {
  count: Scalars['Int'];
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type KoreaSpec = {
  __typename?: 'KoreaSpec';
  CI?: Maybe<Scalars['String']>;
  DI?: Maybe<Scalars['String']>;
  agreements?: Maybe<Scalars['JSON']>;
  bioPubKey?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  cashReceiptNumber?: Maybe<Scalars['String']>;
  cashReceiptType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isForeigner?: Maybe<Scalars['Boolean']>;
  legalName?: Maybe<Scalars['String']>;
  pgAccounts?: Maybe<Scalars['JSON']>;
  pgPrimaryAccount?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  socialGender?: Maybe<Scalars['String']>;
};

export type Ledger = {
  __typename?: 'Ledger';
  cash?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  point?: Maybe<Scalars['Float']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  user?: Maybe<User>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type LuckyPromotion = {
  __typename?: 'LuckyPromotion';
  amountCap?: Maybe<Scalars['Int']>;
  countCap?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  endAt: Scalars['Date'];
  expirationMinute?: Maybe<Scalars['Int']>;
  filter?: Maybe<LuckyPromotionPolicyFilter>;
  id: Scalars['ID'];
  losePromotion?: Maybe<Promotion>;
  merchants?: Maybe<Array<Maybe<Merchant>>>;
  periodCapsPerUser?: Maybe<Array<Maybe<PeriodCapCondition>>>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  probabilities?: Maybe<Array<Maybe<LuckyPromotionPolicyProbabilityUnit>>>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  winPromotion?: Maybe<Promotion>;
};

export type LuckyPromotionList = {
  __typename?: 'LuckyPromotionList';
  list?: Maybe<Array<Maybe<LuckyPromotion>>>;
  total?: Maybe<Scalars['Int']>;
};

export type LuckyPromotionPolicyFilter = {
  __typename?: 'LuckyPromotionPolicyFilter';
  isFirstForLifetime?: Maybe<Scalars['Boolean']>;
  isFirstForMerchant?: Maybe<Scalars['Boolean']>;
  totalMerchantPaymentCount?: Maybe<Scalars['Int']>;
  totalPaymentCount?: Maybe<Scalars['Int']>;
};

export type LuckyPromotionPolicyProbabilityUnit = {
  __typename?: 'LuckyPromotionPolicyProbabilityUnit';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  probability: Scalars['Float'];
};

export type Menu = {
  __typename?: 'Menu';
  actions?: Maybe<Array<Maybe<Action>>>;
  children?: Maybe<Array<Maybe<Menu>>>;
  code?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isLock?: Maybe<Scalars['Boolean']>;
  leftMenu?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type Merchant = {
  __typename?: 'Merchant';
  address?: Maybe<Scalars['String']>;
  aov?: Maybe<Scalars['Float']>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  bankAccountNum?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  businessCategory?: Maybe<Scalars['String']>;
  businessNumber?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  countryCode: Scalars['String'];
  createdAt: Scalars['Date'];
  customerServicePhone?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  displayName: Scalars['String'];
  funnel?: Maybe<Scalars['String']>;
  globalName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkMethod?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  managerEmail?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  managerPhone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentLimits?: Maybe<Scalars['JSON']>;
  pgMerchantCode?: Maybe<Scalars['String']>;
  privateAPIKey?: Maybe<Scalars['String']>;
  publicAPIKey?: Maybe<Scalars['String']>;
  representativeName?: Maybe<Scalars['String']>;
  reserveAmount?: Maybe<Scalars['Int']>;
  restrictedPid?: Maybe<Scalars['JSON']>;
  settlementEmail?: Maybe<Scalars['String']>;
  settlementKey?: Maybe<Scalars['String']>;
  settlementSubEmail?: Maybe<Scalars['String']>;
  share?: Maybe<Scalars['Float']>;
  shortName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  totalBenefit?: Maybe<Scalars['Float']>;
  totalTxVol?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['Date'];
  vertical?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type MerchantAddResult = {
  __typename?: 'MerchantAddResult';
  merchantId: Scalars['String'];
  privateKey: Scalars['String'];
  publicKey: Scalars['String'];
};

export type MerchantList = {
  __typename?: 'MerchantList';
  list?: Maybe<Array<Maybe<Merchant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MerchantNames = {
  __typename?: 'MerchantNames';
  merchantGlobalName?: Maybe<Scalars['String']>;
  merchantName?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
};

export type MonohaBoostPromotionList = {
  __typename?: 'MonohaBoostPromotionList';
  list?: Maybe<Array<Maybe<MonohaBoostPromotionListItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MonohaBoostPromotionListItem = {
  __typename?: 'MonohaBoostPromotionListItem';
  amountCap?: Maybe<Scalars['Int']>;
  boltPrice?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  categoryName?: Maybe<Scalars['String']>;
  countCap?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  onOffLineStatus?: Maybe<Scalars['String']>;
  promotion?: Maybe<Promotion>;
  status?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<Tag>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visibleFrom?: Maybe<Scalars['Date']>;
  visibleTo?: Maybe<Scalars['Date']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _Empty?: Maybe<Scalars['String']>;
  addApiKey?: Maybe<ApiKey>;
  addInvitation?: Maybe<AddInvitationResult>;
  addNotification: NotificationEntity;
  addTag?: Maybe<Tag>;
  agreementAdd?: Maybe<Scalars['ID']>;
  agreementFork?: Maybe<Scalars['ID']>;
  agreementUpdate?: Maybe<Scalars['ID']>;
  applyBoostReport?: Maybe<Scalars['Boolean']>;
  auth?: Maybe<Admin>;
  b2bUserAdd?: Maybe<Scalars['String']>;
  b2bUserBalanceUpdate?: Maybe<Scalars['Boolean']>;
  b2bUserUpdate?: Maybe<Scalars['Boolean']>;
  bankAdd?: Maybe<Scalars['Int']>;
  bankUpdate?: Maybe<Scalars['Int']>;
  bankUploadImage?: Maybe<UploadedImage>;
  bannerAdd?: Maybe<Scalars['ID']>;
  bannerGroupAdd?: Maybe<Scalars['ID']>;
  bannerGroupRemove?: Maybe<Scalars['Boolean']>;
  bannerGroupUpdate?: Maybe<Scalars['ID']>;
  bannerRemove?: Maybe<Scalars['Boolean']>;
  bannerSortedIndexUpdate?: Maybe<Scalars['Boolean']>;
  bannerUpdate?: Maybe<Scalars['ID']>;
  boltDrawPromotionAdd?: Maybe<Scalars['Int']>;
  boltDrawPromotionAddImage?: Maybe<Scalars['Boolean']>;
  boltDrawPromotionDeleteImage?: Maybe<Scalars['Boolean']>;
  boltDrawPromotionSwapImagePriority?: Maybe<Scalars['Boolean']>;
  boltDrawPromotionUpdate?: Maybe<Scalars['Int']>;
  boltDrawPromotionUploadImage?: Maybe<UploadedImage>;
  boltDrawPromotionUpsertImage?: Maybe<Scalars['Boolean']>;
  boltExclusionRuleAdd?: Maybe<Scalars['ID']>;
  boltExclusionRuleUpdate?: Maybe<Scalars['ID']>;
  boltPolicyAdd?: Maybe<Scalars['ID']>;
  boltPolicyUpdate?: Maybe<Scalars['ID']>;
  boostBudgetAdd?: Maybe<Scalars['Int']>;
  boostBudgetUpdate?: Maybe<Scalars['Int']>;
  boostCampaignAdSpendAdd?: Maybe<Scalars['Int']>;
  boostCampaignAdSpendUpdate?: Maybe<Scalars['Int']>;
  boostCampaignAdd?: Maybe<Scalars['Int']>;
  boostCampaignUpdate?: Maybe<Scalars['Int']>;
  boostCategoryAdd?: Maybe<Scalars['Int']>;
  boostCategorySwap?: Maybe<Scalars['Int']>;
  boostCategoryUpdate?: Maybe<Scalars['Int']>;
  boostCategoryUploadImage?: Maybe<UploadedImage>;
  boostExclusionRuleAdd?: Maybe<Scalars['ID']>;
  boostExclusionRuleUpdate?: Maybe<Scalars['ID']>;
  boostImportantAdd?: Maybe<Scalars['JSON']>;
  boostImportantUpdate?: Maybe<Scalars['JSON']>;
  boostMissionPolicyAdd?: Maybe<Scalars['Int']>;
  boostMissionPolicyUpdate?: Maybe<Scalars['Int']>;
  boostPromotionAdd?: Maybe<Scalars['Int']>;
  boostPromotionCopy?: Maybe<Array<Maybe<Scalars['Int']>>>;
  boostPromotionUpdate?: Maybe<Scalars['Int']>;
  boostTargetConditionAdd?: Maybe<Scalars['Int']>;
  boostTargetConditionCopy?: Maybe<Array<Maybe<Scalars['Int']>>>;
  boostTargetConditionUpdate?: Maybe<Scalars['Int']>;
  boostUpAdd?: Maybe<Scalars['JSON']>;
  boostUpImportantUpdate?: Maybe<Scalars['String']>;
  boostUpUpdate?: Maybe<Scalars['JSON']>;
  brandAdd?: Maybe<Scalars['Int']>;
  brandChangeContentStatus?: Maybe<Scalars['Boolean']>;
  brandChangeContentVisible?: Maybe<Scalars['Boolean']>;
  brandDeleteContent?: Maybe<Scalars['Boolean']>;
  brandInsertContentUrls?: Maybe<Scalars['Boolean']>;
  brandSwapContentPriority?: Maybe<Scalars['Boolean']>;
  brandUpdate?: Maybe<Scalars['Int']>;
  brandUploadContent?: Maybe<UploadedContent>;
  brandUploadImage?: Maybe<UploadedImage>;
  cancelPGPayment?: Maybe<Scalars['Boolean']>;
  cancelPayment?: Maybe<Scalars['Boolean']>;
  cardProductAdd?: Maybe<Scalars['Int']>;
  cardProductUpdate?: Maybe<Scalars['Int']>;
  clearVictim?: Maybe<Scalars['String']>;
  confirm?: Maybe<Scalars['String']>;
  confirmReset?: Maybe<Scalars['Boolean']>;
  contractAdd?: Maybe<Contract>;
  contractUpdate?: Maybe<Scalars['String']>;
  couponDisable?: Maybe<Scalars['Int']>;
  couponIssue?: Maybe<CouponIssueResult>;
  couponUpdate?: Maybe<Scalars['Boolean']>;
  createAddTagJob?: Maybe<TagJob>;
  createRemoveTagJob?: Maybe<TagJob>;
  createSettlementTransfer?: Maybe<Scalars['Boolean']>;
  createSettlementTransfers?: Maybe<Scalars['Boolean']>;
  createTagV2?: Maybe<Scalars['Boolean']>;
  deletePGAccount?: Maybe<Scalars['Boolean']>;
  deletePolicyMapping?: Maybe<Scalars['Boolean']>;
  deleteTag?: Maybe<Scalars['Boolean']>;
  deleteTagUserV2?: Maybe<Scalars['Boolean']>;
  deleteTargetMapping?: Maybe<Scalars['Boolean']>;
  deleteTargetMappings?: Maybe<Scalars['Boolean']>;
  exchangePropertyRuleAdd?: Maybe<Scalars['ID']>;
  exchangePropertyRuleDel?: Maybe<Scalars['Boolean']>;
  exchangePropertyRuleUpdate?: Maybe<Scalars['ID']>;
  extendSession?: Maybe<Scalars['String']>;
  faqAdd?: Maybe<Scalars['Boolean']>;
  faqRemove?: Maybe<Scalars['Boolean']>;
  faqUpdate?: Maybe<Scalars['Boolean']>;
  i18nSave?: Maybe<Scalars['ID']>;
  i18nUploadImage?: Maybe<UploadedImage>;
  logout?: Maybe<Scalars['String']>;
  luckyPromotionAdd?: Maybe<Scalars['ID']>;
  luckyPromotionUpdate?: Maybe<Scalars['ID']>;
  makeTagPolicyMapping?: Maybe<Scalars['Boolean']>;
  makeTagUserMapping?: Maybe<Scalars['Boolean']>;
  merchantAdd?: Maybe<MerchantAddResult>;
  merchantBalanceTransfer?: Maybe<Scalars['Boolean']>;
  merchantUpdate?: Maybe<Scalars['String']>;
  modifyNotification: Scalars['Boolean'];
  monohaBoostPromotionUpdate?: Maybe<Scalars['Boolean']>;
  noticeAdd?: Maybe<Scalars['Boolean']>;
  noticeRemove?: Maybe<Scalars['Boolean']>;
  noticeUpdate?: Maybe<Scalars['Boolean']>;
  noticeUploadImage?: Maybe<UploadedImage>;
  paymentCategoryTagAdd?: Maybe<Scalars['Int']>;
  paymentCategoryTagUpdate?: Maybe<Scalars['Int']>;
  paymentTagAdd?: Maybe<Scalars['Int']>;
  paymentTagCopy?: Maybe<Array<Maybe<Scalars['Int']>>>;
  paymentTagUpdate?: Maybe<Scalars['Int']>;
  policyChargeLimitAdd?: Maybe<Scalars['ID']>;
  policyChargeLimitUpdate?: Maybe<Scalars['ID']>;
  policyPGFeeAdd?: Maybe<Scalars['ID']>;
  policyPGFeeUpdate?: Maybe<Scalars['ID']>;
  policyPaymentLimitAdd?: Maybe<Scalars['ID']>;
  policyPaymentLimitUpdate?: Maybe<Scalars['ID']>;
  policyRemove?: Maybe<Scalars['ID']>;
  policyWithdrawalLimitAdd?: Maybe<Scalars['ID']>;
  policyWithdrawalLimitUpdate?: Maybe<Scalars['ID']>;
  promotionAdd?: Maybe<Scalars['ID']>;
  promotionAirdropAdd?: Maybe<Scalars['String']>;
  promotionAirdropUpdate?: Maybe<Scalars['Boolean']>;
  promotionCopy?: Maybe<Promotion>;
  promotionCopyWithDate?: Maybe<Scalars['Int']>;
  promotionGroupAdd?: Maybe<Scalars['String']>;
  promotionGroupUpdate?: Maybe<Scalars['Boolean']>;
  promotionUpdate?: Maybe<Scalars['ID']>;
  registerOTP?: Maybe<Scalars['Boolean']>;
  removeActions?: Maybe<Scalars['Boolean']>;
  removeGroups?: Maybe<Scalars['Boolean']>;
  removeMenu?: Maybe<Scalars['Boolean']>;
  request?: Maybe<Scalars['String']>;
  requestPhoneOTP?: Maybe<Scalars['String']>;
  requestReset?: Maybe<Scalars['Boolean']>;
  retryTagJob?: Maybe<TagJob>;
  revertWithdrawPoint?: Maybe<Scalars['Boolean']>;
  saveAction?: Maybe<Action>;
  saveAdmin?: Maybe<Admin>;
  saveGroup?: Maybe<Group>;
  saveGroupActions?: Maybe<Group>;
  saveGroupMenus?: Maybe<Group>;
  saveMenu?: Maybe<Menu>;
  saveNotificationMessage: Scalars['Boolean'];
  selectPreApplication?: Maybe<SelectPreApplicationResult>;
  sendMonohaUser?: Maybe<Scalars['Boolean']>;
  sendTestNotification: Scalars['Boolean'];
  setAdminAllowedIp?: Maybe<Scalars['Boolean']>;
  setCardServerInspect?: Maybe<CardServerInspectResult>;
  settlementUpdate?: Maybe<Scalars['Boolean']>;
  subMerchantUpdate?: Maybe<Scalars['String']>;
  unRegisterOTP?: Maybe<Scalars['Boolean']>;
  updateAdminGroup?: Maybe<Admin>;
  updateApiKey?: Maybe<ApiKey>;
  updateCashReceipt?: Maybe<Scalars['Boolean']>;
  updateGroupAdmin?: Maybe<Group>;
  updatePassword?: Maybe<Scalars['Boolean']>;
  updateTag?: Maybe<Tag>;
  updateTagV2?: Maybe<Tag>;
  userOffsetDisposal?: Maybe<Scalars['Boolean']>;
  userUnregister?: Maybe<Scalars['Boolean']>;
  userUpdate?: Maybe<Scalars['String']>;
  userWithdrawPoint?: Maybe<Scalars['Boolean']>;
};

export type MutationAddApiKeyArgs = {
  expireAt: Scalars['Date'];
  merchantId: Scalars['String'];
  type: Scalars['String'];
};

export type MutationAddInvitationArgs = {
  invitationCount?: InputMaybe<Scalars['Int']>;
  userIdFile?: InputMaybe<Scalars['Upload']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationAddNotificationArgs = {
  data: NotificationInput;
};

export type MutationAddTagArgs = {
  status: Scalars['String'];
  tagName: Scalars['String'];
};

export type MutationAgreementAddArgs = {
  description: Scalars['String'];
  group: Scalars['String'];
  isActive: Scalars['Boolean'];
  issueDate: Scalars['Date'];
  optional: Scalars['Boolean'];
  sortedIndex: Scalars['Int'];
  termType: Scalars['String'];
  title: Scalars['String'];
};

export type MutationAgreementForkArgs = {
  description: Scalars['String'];
  group: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  issueDate: Scalars['Date'];
  optional: Scalars['Boolean'];
  reagree?: InputMaybe<Scalars['Boolean']>;
  reagreeText?: InputMaybe<Scalars['String']>;
  sortedIndex: Scalars['Int'];
  title: Scalars['String'];
};

export type MutationAgreementUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  issueDate?: InputMaybe<Scalars['Date']>;
  optional?: InputMaybe<Scalars['Boolean']>;
  reagree?: InputMaybe<Scalars['Boolean']>;
  reagreeText?: InputMaybe<Scalars['String']>;
  sortedIndex: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type MutationApplyBoostReportArgs = {
  id: Scalars['ID'];
  isBoostReportApprove?: InputMaybe<Scalars['Boolean']>;
  rejectReason?: InputMaybe<Scalars['String']>;
};

export type MutationAuthArgs = {
  publicKey?: InputMaybe<Scalars['String']>;
};

export type MutationB2bUserAddArgs = {
  loginId: Scalars['String'];
  merchantId: Scalars['String'];
  password: Scalars['String'];
  permittedMerchantUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationB2bUserBalanceUpdateArgs = {
  amount: Scalars['BigInt'];
  currency: Scalars['String'];
  date: Scalars['Date'];
  exchangeRate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  remittanceAmount: Scalars['String'];
};

export type MutationB2bUserUpdateArgs = {
  id: Scalars['ID'];
  password?: InputMaybe<Scalars['String']>;
  permittedMerchantUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type MutationBankAddArgs = {
  bankCode: Scalars['String'];
  code: Scalars['String'];
  isActive: Scalars['Boolean'];
  isRegistrable: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  sortedIndex: Scalars['Int'];
};

export type MutationBankUpdateArgs = {
  bankCode: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isRegistrable: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  sortedIndex: Scalars['Int'];
};

export type MutationBankUploadImageArgs = {
  fileName?: InputMaybe<Scalars['String']>;
  imageFile: Scalars['Upload'];
};

export type MutationBannerAddArgs = {
  androidImageFile: Scalars['Upload'];
  endAt?: InputMaybe<Scalars['Date']>;
  groupId: Scalars['ID'];
  iosImageFile: Scalars['Upload'];
  isActive: Scalars['Boolean'];
  landingUrl: Scalars['String'];
  sortedIndex: Scalars['Int'];
  startAt?: InputMaybe<Scalars['Date']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MutationBannerGroupAddArgs = {
  name: Scalars['String'];
};

export type MutationBannerGroupRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationBannerGroupUpdateArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationBannerRemoveArgs = {
  id?: InputMaybe<Scalars['ID']>;
  idList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationBannerSortedIndexUpdateArgs = {
  sortingList: Array<InputMaybe<BannerSortingList>>;
};

export type MutationBannerUpdateArgs = {
  androidImageFile?: InputMaybe<Scalars['Upload']>;
  endAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  iosImageFile?: InputMaybe<Scalars['Upload']>;
  isActive: Scalars['Boolean'];
  landingUrl: Scalars['String'];
  sortedIndex: Scalars['Int'];
  startAt?: InputMaybe<Scalars['Date']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MutationBoltDrawPromotionAddArgs = {
  bettingCountLimit: Scalars['Int'];
  bettingTimeLimitMin: Scalars['Int'];
  description: Scalars['String'];
  enterableFrom: Scalars['Date'];
  enterableTo: Scalars['Date'];
  important: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  visibleFrom: Scalars['Date'];
  visibleTo: Scalars['Date'];
  winnerCount: Scalars['Int'];
};

export type MutationBoltDrawPromotionAddImageArgs = {
  boltDrawPromotionId: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type MutationBoltDrawPromotionDeleteImageArgs = {
  id: Scalars['Int'];
};

export type MutationBoltDrawPromotionSwapImagePriorityArgs = {
  imageId: Scalars['Int'];
  imageIdToSwap: Scalars['Int'];
};

export type MutationBoltDrawPromotionUpdateArgs = {
  bettingCountLimit?: InputMaybe<Scalars['Int']>;
  bettingTimeLimitMin?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  enterableFrom?: InputMaybe<Scalars['Date']>;
  enterableTo?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  important?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  visibleFrom?: InputMaybe<Scalars['Date']>;
  visibleTo?: InputMaybe<Scalars['Date']>;
  winnerCount?: InputMaybe<Scalars['Int']>;
};

export type MutationBoltDrawPromotionUploadImageArgs = {
  imageFile: Scalars['Upload'];
};

export type MutationBoltDrawPromotionUpsertImageArgs = {
  boltDrawPromotionId: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type MutationBoltExclusionRuleAddArgs = {
  code: Scalars['String'];
  codeType: Scalars['String'];
  limit: Scalars['Int'];
  maxBoltCount?: InputMaybe<Scalars['Int']>;
  period: Scalars['String'];
  policyType: Scalars['String'];
  receiverType: Scalars['String'];
};

export type MutationBoltExclusionRuleUpdateArgs = {
  code: Scalars['String'];
  codeType: Scalars['String'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  maxBoltCount?: InputMaybe<Scalars['Int']>;
  period: Scalars['String'];
  policyType: Scalars['String'];
  receiverType: Scalars['String'];
};

export type MutationBoltPolicyAddArgs = {
  airdropUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['Date']>;
  expirationRule?: InputMaybe<ExpirationRuleInput>;
  issueCountRuleCount?: InputMaybe<Scalars['Int']>;
  issueCountRuleRange?: InputMaybe<Array<InputMaybe<IssueCountRuleRangeInput>>>;
  issueCountRuleType: Scalars['String'];
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  periodCapsPerUserRuleCount?: InputMaybe<Scalars['Int']>;
  periodCapsPerUserRuleRange?: InputMaybe<Array<InputMaybe<PeriodCapsPerUserRuleRangeInput>>>;
  periodCapsPerUserRuleType: Scalars['String'];
  receiverType: Scalars['String'];
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type MutationBoltPolicyUpdateArgs = {
  airdropUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['Date']>;
  expirationRule?: InputMaybe<ExpirationRuleInput>;
  id: Scalars['ID'];
  issueCountRuleCount?: InputMaybe<Scalars['Int']>;
  issueCountRuleRange?: InputMaybe<Array<InputMaybe<IssueCountRuleRangeInput>>>;
  issueCountRuleType: Scalars['String'];
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  periodCapsPerUserRuleCount?: InputMaybe<Scalars['Int']>;
  periodCapsPerUserRuleRange?: InputMaybe<Array<InputMaybe<PeriodCapsPerUserRuleRangeInput>>>;
  periodCapsPerUserRuleType: Scalars['String'];
  receiverType: Scalars['String'];
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type MutationBoostBudgetAddArgs = {
  adSpend?: InputMaybe<Scalars['BigInt']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  brandId: Scalars['Int'];
  chaiCredit?: InputMaybe<Scalars['BigInt']>;
  dailyCap?: InputMaybe<Scalars['BigInt']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MutationBoostBudgetUpdateArgs = {
  adSpend?: InputMaybe<Scalars['BigInt']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  brandId?: InputMaybe<Scalars['Int']>;
  chaiCredit?: InputMaybe<Scalars['BigInt']>;
  dailyCap?: InputMaybe<Scalars['BigInt']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationBoostCampaignAdSpendAddArgs = {
  billingType: Scalars['String'];
  boostCampaignId: Scalars['Int'];
  ratio?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  unitPrice?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type MutationBoostCampaignAdSpendUpdateArgs = {
  billingType?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  ratio?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type MutationBoostCampaignAddArgs = {
  adSpendCalculationType?: InputMaybe<Scalars['String']>;
  adSpendRatio?: InputMaybe<Scalars['Float']>;
  adSpendUnitPrice?: InputMaybe<Scalars['BigInt']>;
  adSpendWeight?: InputMaybe<Scalars['Float']>;
  amountCap?: InputMaybe<Scalars['BigInt']>;
  animationType?: InputMaybe<Scalars['String']>;
  attributionUrl?: InputMaybe<Scalars['String']>;
  boostBudgetId: Scalars['Int'];
  boostCampaignContents?: InputMaybe<Array<InputMaybe<BoostCampaignContentInput>>>;
  boostPromotionId: Scalars['Int'];
  boostUpPolicy?: InputMaybe<Scalars['Int']>;
  brandId: Scalars['Int'];
  buyableFrom?: InputMaybe<Scalars['String']>;
  buyableTo?: InputMaybe<Scalars['String']>;
  countCap?: InputMaybe<Scalars['Int']>;
  dailyAmountCap?: InputMaybe<Scalars['BigInt']>;
  dailyCountCapPerUser?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  end: Scalars['Date'];
  importantNotice?: InputMaybe<Scalars['String']>;
  notice?: InputMaybe<Scalars['String']>;
  start: Scalars['Date'];
  status?: InputMaybe<Scalars['String']>;
  targetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetUserTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
  totalCountCapPerUser?: InputMaybe<Scalars['Int']>;
  usableFrom?: InputMaybe<Scalars['String']>;
  usableTo?: InputMaybe<Scalars['String']>;
};

export type MutationBoostCampaignUpdateArgs = {
  adSpendCalculationType?: InputMaybe<Scalars['String']>;
  adSpendRatio?: InputMaybe<Scalars['Float']>;
  adSpendUnitPrice?: InputMaybe<Scalars['BigInt']>;
  adSpendWeight?: InputMaybe<Scalars['Float']>;
  amountCap?: InputMaybe<Scalars['BigInt']>;
  animationType?: InputMaybe<Scalars['String']>;
  attributionUrl?: InputMaybe<Scalars['String']>;
  boostBudgetId?: InputMaybe<Scalars['Int']>;
  boostCampaignContents?: InputMaybe<Array<InputMaybe<BoostCampaignContentInput>>>;
  boostPromotionId?: InputMaybe<Scalars['Int']>;
  boostUpPolicy?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  buyableFrom?: InputMaybe<Scalars['String']>;
  buyableTo?: InputMaybe<Scalars['String']>;
  countCap?: InputMaybe<Scalars['Int']>;
  dailyAmountCap?: InputMaybe<Scalars['BigInt']>;
  dailyCountCapPerUser?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  importantNotice?: InputMaybe<Scalars['String']>;
  notice?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  targetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetUserTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  totalCountCapPerUser?: InputMaybe<Scalars['Int']>;
  usableFrom?: InputMaybe<Scalars['String']>;
  usableTo?: InputMaybe<Scalars['String']>;
};

export type MutationBoostCategoryAddArgs = {
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Int'];
};

export type MutationBoostCategorySwapArgs = {
  firstId: Scalars['Int'];
  secondId: Scalars['Int'];
};

export type MutationBoostCategoryUpdateArgs = {
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type MutationBoostCategoryUploadImageArgs = {
  imageFile: Scalars['Upload'];
};

export type MutationBoostExclusionRuleAddArgs = {
  code: Scalars['String'];
  codeType: Scalars['String'];
  type: Scalars['String'];
};

export type MutationBoostExclusionRuleUpdateArgs = {
  code: Scalars['String'];
  codeType: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type MutationBoostImportantAddArgs = {
  content: Scalars['String'];
  priority: Scalars['Int'];
  table: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MutationBoostImportantUpdateArgs = {
  content: Scalars['String'];
  prevSortKey: Scalars['String'];
  priority: Scalars['Int'];
  sortKey: Scalars['String'];
  table: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MutationBoostMissionPolicyAddArgs = {
  boltPolicyId?: InputMaybe<Scalars['Int']>;
  boostCampaignId?: InputMaybe<Scalars['Int']>;
  buyableToOffsetHour?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  conditionType: Scalars['String'];
  criteria?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endAt: Scalars['Date'];
  missionUrl?: InputMaybe<Scalars['String']>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  usableToOffsetHour?: InputMaybe<Scalars['Int']>;
  visibleToOffsetHour?: InputMaybe<Scalars['Int']>;
};

export type MutationBoostMissionPolicyUpdateArgs = {
  boltPolicyId?: InputMaybe<Scalars['Int']>;
  boostCampaignId?: InputMaybe<Scalars['Int']>;
  buyableToOffsetHour?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  conditionType: Scalars['String'];
  criteria?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endAt: Scalars['Date'];
  id: Scalars['Int'];
  missionUrl?: InputMaybe<Scalars['String']>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  usableToOffsetHour?: InputMaybe<Scalars['Int']>;
  visibleToOffsetHour?: InputMaybe<Scalars['Int']>;
};

export type MutationBoostPromotionAddArgs = {
  amountCap?: InputMaybe<Scalars['Int']>;
  androidPackage?: InputMaybe<Scalars['String']>;
  benefitDescription?: InputMaybe<Scalars['String']>;
  benefitType?: InputMaybe<Scalars['String']>;
  boltPrice: Scalars['Int'];
  boostCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  boostMerchantIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boostTargetConditionId?: InputMaybe<Scalars['Int']>;
  brandId: Scalars['Int'];
  buyableFrom?: InputMaybe<Scalars['Date']>;
  buyableTo?: InputMaybe<Scalars['Date']>;
  countCap?: InputMaybe<Scalars['Int']>;
  countCapPerUser?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  discount?: InputMaybe<Scalars['String']>;
  discountAmountMax?: InputMaybe<Scalars['String']>;
  discountAmountMin?: InputMaybe<Scalars['String']>;
  existingPolicyId?: InputMaybe<Scalars['String']>;
  fallbackUrl?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  important?: InputMaybe<Scalars['String']>;
  iosAppId?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  onOffLineStatus?: InputMaybe<Scalars['String']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  promotionType?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  subPromotions?: InputMaybe<Array<InputMaybe<SubPromotionInput>>>;
  subTitle: Scalars['String'];
  taglines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetMerchantIds?: InputMaybe<Scalars['JSON']>;
  title: Scalars['String'];
  tutorialType?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  typoImageUrl?: InputMaybe<Scalars['String']>;
  usableFrom?: InputMaybe<Scalars['Date']>;
  usableLocationDescription?: InputMaybe<Scalars['String']>;
  usableTo?: InputMaybe<Scalars['Date']>;
  visibleFrom?: InputMaybe<Scalars['Date']>;
  visibleTo?: InputMaybe<Scalars['Date']>;
};

export type MutationBoostPromotionCopyArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
  replaceTextFrom?: InputMaybe<Scalars['String']>;
  replaceTextTo?: InputMaybe<Scalars['String']>;
  targetDate?: InputMaybe<Scalars['Date']>;
};

export type MutationBoostPromotionUpdateArgs = {
  amountCap?: InputMaybe<Scalars['Int']>;
  androidPackage?: InputMaybe<Scalars['String']>;
  benefitDescription?: InputMaybe<Scalars['String']>;
  benefitType?: InputMaybe<Scalars['String']>;
  boltPrice: Scalars['Int'];
  boostCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  boostMerchantIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boostTargetConditionId?: InputMaybe<Scalars['Int']>;
  brandId: Scalars['Int'];
  buyableFrom?: InputMaybe<Scalars['Date']>;
  buyableTo?: InputMaybe<Scalars['Date']>;
  countCap?: InputMaybe<Scalars['Int']>;
  countCapPerUser?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  discount?: InputMaybe<Scalars['String']>;
  discountAmountMax?: InputMaybe<Scalars['String']>;
  discountAmountMin?: InputMaybe<Scalars['String']>;
  existingPolicyId?: InputMaybe<Scalars['String']>;
  fallbackUrl?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  important?: InputMaybe<Scalars['String']>;
  iosAppId?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  onOffLineStatus?: InputMaybe<Scalars['String']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  promotionType?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  subPromotions?: InputMaybe<Array<InputMaybe<SubPromotionInput>>>;
  subTitle: Scalars['String'];
  taglines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetMerchantIds?: InputMaybe<Scalars['JSON']>;
  title: Scalars['String'];
  tutorialType?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  typoImageUrl?: InputMaybe<Scalars['String']>;
  usableFrom?: InputMaybe<Scalars['Date']>;
  usableLocationDescription?: InputMaybe<Scalars['String']>;
  usableTo?: InputMaybe<Scalars['Date']>;
  visibleFrom?: InputMaybe<Scalars['Date']>;
  visibleTo?: InputMaybe<Scalars['Date']>;
};

export type MutationBoostTargetConditionAddArgs = {
  condition: Scalars['JSON'];
  conditionText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MutationBoostTargetConditionCopyArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type MutationBoostTargetConditionUpdateArgs = {
  condition?: InputMaybe<Scalars['JSON']>;
  conditionText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type MutationBoostUpAddArgs = {
  boltPrice?: InputMaybe<Scalars['Int']>;
  boostUpSchemePolicies?: InputMaybe<Array<InputMaybe<BoostUpSchemeInput>>>;
  budget?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  possibleMoments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  targetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
  visibleFrom?: InputMaybe<Scalars['Date']>;
  visibleTo?: InputMaybe<Scalars['Date']>;
};

export type MutationBoostUpImportantUpdateArgs = {
  value: Scalars['String'];
};

export type MutationBoostUpUpdateArgs = {
  boltPrice?: InputMaybe<Scalars['Int']>;
  boostUpSchemePolicies?: InputMaybe<Array<InputMaybe<BoostUpSchemeInput>>>;
  budget?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  possibleMoments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  targetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
  visibleFrom?: InputMaybe<Scalars['Date']>;
  visibleTo?: InputMaybe<Scalars['Date']>;
};

export type MutationBrandAddArgs = {
  androidPackage?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  fallbackUrl?: InputMaybe<Scalars['String']>;
  iosAppId?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  logoImageUrl: Scalars['String'];
  name: Scalars['String'];
  typoImageUrl: Scalars['String'];
};

export type MutationBrandChangeContentStatusArgs = {
  bucketFileName: Scalars['String'];
  errorMessage?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type MutationBrandChangeContentVisibleArgs = {
  contentId: Scalars['Int'];
  visible: Scalars['String'];
};

export type MutationBrandDeleteContentArgs = {
  contentId: Scalars['Int'];
};

export type MutationBrandInsertContentUrlsArgs = {
  bucketFileName: Scalars['String'];
  urls: Array<InputMaybe<ContentUrl>>;
};

export type MutationBrandSwapContentPriorityArgs = {
  contentId: Scalars['Int'];
  contentIdToSwap: Scalars['Int'];
};

export type MutationBrandUpdateArgs = {
  androidPackage?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  fallbackUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  iosAppId?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  typoImageUrl?: InputMaybe<Scalars['String']>;
};

export type MutationBrandUploadContentArgs = {
  brandId: Scalars['Int'];
  file: Scalars['Upload'];
  sourceFileName: Scalars['String'];
  visible: Scalars['String'];
};

export type MutationBrandUploadImageArgs = {
  imageFile: Scalars['Upload'];
};

export type MutationCancelPgPaymentArgs = {
  id: Scalars['ID'];
};

export type MutationCancelPaymentArgs = {
  cancelAmount: Scalars['Int'];
  id: Scalars['ID'];
  publicAPIKey: Scalars['String'];
};

export type MutationCardProductAddArgs = {
  annualFee: Scalars['Int'];
  benefitImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brandCode: Scalars['String'];
  description: Scalars['String'];
  descriptionImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  emphasizeColor1?: InputMaybe<Scalars['String']>;
  emphasizeColor2?: InputMaybe<Scalars['String']>;
  emphasizeText?: InputMaybe<Scalars['String']>;
  externalIssueUrl?: InputMaybe<Scalars['String']>;
  isDomestic: Scalars['Boolean'];
  issueBoltPrice: Scalars['Int'];
  issueTarget: Scalars['String'];
  issuer: Scalars['String'];
  logoImageUrl?: InputMaybe<Scalars['String']>;
  maxEarlyBirdCount?: InputMaybe<Scalars['Int']>;
  maxMonthlyBenefitAmount: Scalars['Int'];
  productCode: Scalars['String'];
  reissueBoltPrice: Scalars['Int'];
  resourceType: Scalars['String'];
  shareableUrl?: InputMaybe<Scalars['String']>;
  sortedIndex: Scalars['Int'];
  status?: InputMaybe<Scalars['String']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  titleDescription?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MutationCardProductUpdateArgs = {
  annualFee?: InputMaybe<Scalars['Int']>;
  benefitImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brandCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  emphasizeColor1?: InputMaybe<Scalars['String']>;
  emphasizeColor2?: InputMaybe<Scalars['String']>;
  emphasizeText?: InputMaybe<Scalars['String']>;
  externalIssueUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isDomestic?: InputMaybe<Scalars['Boolean']>;
  issueBoltPrice?: InputMaybe<Scalars['Int']>;
  issueTarget?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  maxEarlyBirdCount?: InputMaybe<Scalars['Int']>;
  maxMonthlyBenefitAmount?: InputMaybe<Scalars['Int']>;
  productCode?: InputMaybe<Scalars['String']>;
  reissueBoltPrice?: InputMaybe<Scalars['Int']>;
  resourceType?: InputMaybe<Scalars['String']>;
  shareableUrl?: InputMaybe<Scalars['String']>;
  sortedIndex?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDescription?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationClearVictimArgs = {
  id: Scalars['ID'];
};

export type MutationConfirmArgs = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationConfirmResetArgs = {
  birth: Scalars['String'];
  code: Scalars['String'];
  foreigner: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  sex: Scalars['String'];
  uid: Scalars['String'];
};

export type MutationContractAddArgs = {
  autoExtension: Scalars['String'];
  fee: Scalars['String'];
  feeType: Scalars['String'];
  includedVat: Scalars['Boolean'];
  merchantId: Scalars['ID'];
  settlementCycle: Scalars['Int'];
  settlementProvideCycle: Scalars['String'];
  termEndAt: Scalars['Date'];
  termStartAt: Scalars['Date'];
  type: Scalars['String'];
};

export type MutationContractUpdateArgs = {
  autoExtension: Scalars['String'];
  fee: Scalars['String'];
  id: Scalars['ID'];
  settlementCycle: Scalars['Int'];
  settlementProvideCycle: Scalars['String'];
  termEndAt: Scalars['Date'];
  termStartAt: Scalars['Date'];
  type: Scalars['String'];
};

export type MutationCouponDisableArgs = {
  promotionId: Scalars['ID'];
};

export type MutationCouponIssueArgs = {
  promotionId: Scalars['ID'];
  userIdFile?: InputMaybe<Scalars['Upload']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationCouponUpdateArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type MutationCreateAddTagJobArgs = {
  s3Key: Scalars['String'];
  tagCategory?: InputMaybe<Scalars['String']>;
  tagName: Scalars['String'];
};

export type MutationCreateRemoveTagJobArgs = {
  s3Key: Scalars['String'];
  tagCategory?: InputMaybe<Scalars['String']>;
  tagName: Scalars['String'];
};

export type MutationCreateSettlementTransferArgs = {
  input: SettlementTransferInput;
  otp: Scalars['String'];
};

export type MutationCreateSettlementTransfersArgs = {
  input: Array<SettlementTransferInput>;
  otp: Scalars['String'];
};

export type MutationCreateTagV2Args = {
  action: Scalars['String'];
  tagName: Scalars['String'];
  targetIdFile: Scalars['Upload'];
};

export type MutationDeletePgAccountArgs = {
  accountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  createdAt?: InputMaybe<Scalars['String']>;
  sequenceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationDeletePolicyMappingArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTagArgs = {
  tagId: Scalars['Int'];
};

export type MutationDeleteTagUserV2Args = {
  tagName: Scalars['String'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationDeleteTargetMappingArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTargetMappingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type MutationExchangePropertyRuleAddArgs = {
  code: Scalars['String'];
  ruleCodeType: ExchangeRuleCodeType;
};

export type MutationExchangePropertyRuleDelArgs = {
  id: Scalars['ID'];
};

export type MutationExchangePropertyRuleUpdateArgs = {
  code: Scalars['String'];
  id: Scalars['ID'];
  ruleCodeType: ExchangeRuleCodeType;
};

export type MutationFaqAddArgs = {
  category: Scalars['String'];
  content: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type MutationFaqRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationFaqUpdateArgs = {
  category: Scalars['String'];
  content: Scalars['String'];
  id: Scalars['ID'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type MutationI18nSaveArgs = {
  columnName: Scalars['String'];
  locale: Scalars['String'];
  tableName: Scalars['String'];
  targetId: Scalars['String'];
  text: Scalars['String'];
};

export type MutationI18nUploadImageArgs = {
  imageFile: Scalars['Upload'];
};

export type MutationLuckyPromotionAddArgs = {
  amountCap?: InputMaybe<Scalars['Int']>;
  countCap?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  endAt: Scalars['Date'];
  expirationMinute: Scalars['Int'];
  isFirstForLifetime?: InputMaybe<Scalars['Boolean']>;
  isFirstForMerchant?: InputMaybe<Scalars['Boolean']>;
  losePromotion?: InputMaybe<PromotionInput>;
  merchantIds: Array<InputMaybe<Scalars['ID']>>;
  periodCapsPerUser?: InputMaybe<Array<InputMaybe<PeriodCapInput>>>;
  pid?: InputMaybe<Scalars['String']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  probabilities?: InputMaybe<Array<InputMaybe<ProbabilityInput>>>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  totalMerchantPaymentCount?: InputMaybe<Scalars['Int']>;
  totalPaymentCount?: InputMaybe<Scalars['Int']>;
  winPromotion?: InputMaybe<PromotionInput>;
};

export type MutationLuckyPromotionUpdateArgs = {
  amountCap?: InputMaybe<Scalars['Int']>;
  countCap?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  endAt: Scalars['Date'];
  expirationMinute?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isFirstForLifetime?: InputMaybe<Scalars['Boolean']>;
  isFirstForMerchant?: InputMaybe<Scalars['Boolean']>;
  losePromotion?: InputMaybe<PromotionInput>;
  merchantIds: Array<InputMaybe<Scalars['ID']>>;
  periodCapsPerUser?: InputMaybe<Array<InputMaybe<PeriodCapInput>>>;
  pid?: InputMaybe<Scalars['String']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  probabilities?: InputMaybe<Array<InputMaybe<ProbabilityInput>>>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  title: Scalars['String'];
  totalMerchantPaymentCount?: InputMaybe<Scalars['Int']>;
  totalPaymentCount?: InputMaybe<Scalars['Int']>;
  winPromotion?: InputMaybe<PromotionInput>;
};

export type MutationMakeTagPolicyMappingArgs = {
  policyId: Scalars['String'];
  policyType: Scalars['String'];
  tagName: Scalars['String'];
};

export type MutationMakeTagUserMappingArgs = {
  tagName: Scalars['String'];
  targetIdFile: Scalars['Upload'];
  targetType: Scalars['String'];
};

export type MutationMerchantAddArgs = {
  address?: InputMaybe<Scalars['String']>;
  bankAccountNum?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankHolder?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  businessCategory?: InputMaybe<Scalars['String']>;
  businessNumber?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<Scalars['String']>;
  contractStatus?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  customerServicePhone?: InputMaybe<Scalars['String']>;
  displayName: Scalars['String'];
  funnel?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  isPointRestricted?: InputMaybe<Scalars['Boolean']>;
  linkMethod?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  managerEmail?: InputMaybe<Scalars['String']>;
  managerName?: InputMaybe<Scalars['String']>;
  managerPhone?: InputMaybe<Scalars['String']>;
  maxMonthlyCashbackAmount?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  paymentLimits?: InputMaybe<Scalars['JSON']>;
  pgMerchantCode?: InputMaybe<Scalars['String']>;
  representativeName?: InputMaybe<Scalars['String']>;
  reserveAmount?: InputMaybe<Scalars['Int']>;
  restrictedPid?: InputMaybe<Scalars['JSON']>;
  settlementEmail?: InputMaybe<Scalars['String']>;
  settlementSubEmail?: InputMaybe<Scalars['String']>;
  shortName: Scalars['String'];
  status: Scalars['String'];
  vertical: Scalars['String'];
  website: Scalars['String'];
};

export type MutationMerchantBalanceTransferArgs = {
  amount: Scalars['Int'];
  fromMerchantId: Scalars['String'];
  toMerchantId: Scalars['String'];
};

export type MutationMerchantUpdateArgs = {
  address?: InputMaybe<Scalars['String']>;
  bankAccountNum?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankHolder?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  businessCategory?: InputMaybe<Scalars['String']>;
  businessNumber?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<Scalars['String']>;
  contractStatus?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customerServicePhone?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  funnel?: InputMaybe<Scalars['String']>;
  globalName?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPointRestricted?: InputMaybe<Scalars['Boolean']>;
  linkMethod?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  managerEmail?: InputMaybe<Scalars['String']>;
  managerName?: InputMaybe<Scalars['String']>;
  managerPhone?: InputMaybe<Scalars['String']>;
  maxMonthlyCashbackAmount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  paymentLimits?: InputMaybe<Scalars['JSON']>;
  pgMerchantCode?: InputMaybe<Scalars['String']>;
  representativeName?: InputMaybe<Scalars['String']>;
  reserveAmount?: InputMaybe<Scalars['Int']>;
  restrictedPid?: InputMaybe<Scalars['JSON']>;
  settlementEmail?: InputMaybe<Scalars['String']>;
  settlementSubEmail?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vertical?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type MutationModifyNotificationArgs = {
  data: NotificationInput;
  notificationId: Scalars['ID'];
};

export type MutationMonohaBoostPromotionUpdateArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type MutationNoticeAddArgs = {
  content: Scalars['String'];
  fixed: Scalars['Boolean'];
  removedFiles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status: Scalars['String'];
  title: Scalars['String'];
  uploadedFiles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationNoticeRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationNoticeUpdateArgs = {
  content: Scalars['String'];
  fixed: Scalars['Boolean'];
  id: Scalars['ID'];
  removedFiles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status: Scalars['String'];
  title: Scalars['String'];
  uploadedFiles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationNoticeUploadImageArgs = {
  imageFile?: InputMaybe<Scalars['Upload']>;
};

export type MutationPaymentCategoryTagAddArgs = {
  name: Scalars['String'];
  paymentTagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type MutationPaymentCategoryTagUpdateArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  paymentTagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type MutationPaymentTagAddArgs = {
  condition: Scalars['JSON'];
  conditionText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationPaymentTagCopyArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type MutationPaymentTagUpdateArgs = {
  condition?: InputMaybe<Scalars['JSON']>;
  conditionText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationPolicyChargeLimitAddArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxBalance: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAmount: Scalars['Float'];
  minUnit: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPolicyChargeLimitUpdateArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxBalance: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAmount: Scalars['Float'];
  minUnit: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPolicyPgFeeAddArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  fee: Scalars['String'];
  name: Scalars['String'];
  startAt: Scalars['Date'];
  type: Scalars['String'];
};

export type MutationPolicyPgFeeUpdateArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  fee: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startAt: Scalars['Date'];
  type: Scalars['String'];
};

export type MutationPolicyPaymentLimitAddArgs = {
  bank?: InputMaybe<Scalars['String']>;
  constraint?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAge?: InputMaybe<Scalars['Int']>;
  minAmount: Scalars['Float'];
  minUnit: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPolicyPaymentLimitUpdateArgs = {
  bank?: InputMaybe<Scalars['String']>;
  constraint?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  maxAge?: InputMaybe<Scalars['Int']>;
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAge?: InputMaybe<Scalars['Int']>;
  minAmount: Scalars['Float'];
  minUnit: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPolicyRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationPolicyWithdrawalLimitAddArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  fee: Scalars['String'];
  maxAmount: Scalars['Float'];
  maxCountPerDay: Scalars['Int'];
  minAmount: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPolicyWithdrawalLimitUpdateArgs = {
  description: Scalars['String'];
  endAt?: InputMaybe<Scalars['Date']>;
  fee: Scalars['String'];
  id: Scalars['ID'];
  maxAmount: Scalars['Float'];
  maxCountPerDay: Scalars['Int'];
  minAmount: Scalars['Float'];
  startAt: Scalars['Date'];
};

export type MutationPromotionAddArgs = {
  benefitType: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  discount: Scalars['String'];
  discountSplitConditions?: InputMaybe<Array<InputMaybe<DiscountSplitConditionInput>>>;
  endAt: Scalars['Date'];
  isBulkIssuable?: InputMaybe<Scalars['Boolean']>;
  isMerchantLifetimePromotion?: InputMaybe<Scalars['Boolean']>;
  isUsableOnAutoCharge?: InputMaybe<Scalars['Boolean']>;
  isUsableOnFirstPurchase?: InputMaybe<Scalars['Boolean']>;
  issueType?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Int']>;
  maxPerPolicy?: InputMaybe<Scalars['Int']>;
  maxPerUser?: InputMaybe<Scalars['Int']>;
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  policyType: Scalars['String'];
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  subMerchantIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  title: Scalars['String'];
  ttl?: InputMaybe<Scalars['Int']>;
  ttlType?: InputMaybe<Scalars['String']>;
};

export type MutationPromotionAirdropAddArgs = {
  issueAt?: InputMaybe<Scalars['Date']>;
  promotionId: Scalars['ID'];
  userIdFile?: InputMaybe<Scalars['Upload']>;
};

export type MutationPromotionAirdropUpdateArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type MutationPromotionCopyArgs = {
  id: Scalars['ID'];
};

export type MutationPromotionCopyWithDateArgs = {
  targetDate: Scalars['Date'];
  targetIds: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationPromotionGroupAddArgs = {
  description?: InputMaybe<Scalars['String']>;
  detailTitle: Scalars['String'];
  endAt: Scalars['Date'];
  landingUrl?: InputMaybe<Scalars['String']>;
  merchantId: Scalars['String'];
  promotions?: InputMaybe<Scalars['JSON']>;
  sortedIndex?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type MutationPromotionGroupUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  detailTitle: Scalars['String'];
  endAt: Scalars['Date'];
  id: Scalars['ID'];
  landingUrl?: InputMaybe<Scalars['String']>;
  merchantId: Scalars['String'];
  promotions?: InputMaybe<Scalars['JSON']>;
  sortedIndex?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  status?: InputMaybe<Scalars['String']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type MutationPromotionUpdateArgs = {
  benefitType: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  discount: Scalars['String'];
  discountSplitConditions?: InputMaybe<Array<InputMaybe<DiscountSplitConditionInput>>>;
  endAt: Scalars['Date'];
  id: Scalars['ID'];
  isBulkIssuable?: InputMaybe<Scalars['Boolean']>;
  isMerchantLifetimePromotion?: InputMaybe<Scalars['Boolean']>;
  isUsableOnAutoCharge?: InputMaybe<Scalars['Boolean']>;
  isUsableOnFirstPurchase?: InputMaybe<Scalars['Boolean']>;
  issueType?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Int']>;
  maxPerPolicy?: InputMaybe<Scalars['Int']>;
  maxPerUser?: InputMaybe<Scalars['Int']>;
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  policyType: Scalars['String'];
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['Date'];
  status: Scalars['String'];
  subMerchantIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  title: Scalars['String'];
  ttl?: InputMaybe<Scalars['Int']>;
  ttlType?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterOtpArgs = {
  confirmCode: Scalars['String'];
};

export type MutationRemoveActionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationRemoveGroupsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationRemoveMenuArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationRequestArgs = {
  id: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type MutationRequestResetArgs = {
  birth: Scalars['String'];
  carrier: Scalars['String'];
  foreigner: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  sex: Scalars['String'];
};

export type MutationRetryTagJobArgs = {
  jobId: Scalars['Int'];
};

export type MutationRevertWithdrawPointArgs = {
  topupId: Scalars['ID'];
};

export type MutationSaveActionArgs = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  groupIds: Array<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  menuId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MutationSaveAdminArgs = {
  allowedIp?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  fullname: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isMerchantGroup?: InputMaybe<Scalars['Boolean']>;
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type MutationSaveGroupArgs = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type MutationSaveGroupActionsArgs = {
  actionIds: Array<InputMaybe<Scalars['ID']>>;
  groupId: Scalars['ID'];
};

export type MutationSaveGroupMenusArgs = {
  groupId: Scalars['ID'];
  menuIds: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationSaveMenuArgs = {
  code?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLock?: InputMaybe<Scalars['Boolean']>;
  leftMenu?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type MutationSaveNotificationMessageArgs = {
  data: Array<NotificationMessageInput>;
  notificationId: Scalars['ID'];
};

export type MutationSelectPreApplicationArgs = {
  invitationCount?: InputMaybe<Scalars['Int']>;
  userIdFile?: InputMaybe<Scalars['Upload']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationSendMonohaUserArgs = {
  s3Key: Scalars['String'];
};

export type MutationSendTestNotificationArgs = {
  notification?: InputMaybe<NotificationWithMessageInput>;
  to: Scalars['String'];
};

export type MutationSetAdminAllowedIpArgs = {
  id: Scalars['ID'];
  ipList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationSetCardServerInspectArgs = {
  doOpen: Scalars['Boolean'];
  isTest?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSettlementUpdateArgs = {
  adjustmentList?: InputMaybe<Array<InputMaybe<Adjustment>>>;
  merchantIds: Array<InputMaybe<Scalars['String']>>;
  otp?: InputMaybe<Scalars['String']>;
  transferException?: InputMaybe<Scalars['String']>;
};

export type MutationSubMerchantUpdateArgs = {
  address?: InputMaybe<Scalars['String']>;
  businessNumber?: InputMaybe<Scalars['String']>;
  customerServicePhone?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  managerEmail?: InputMaybe<Scalars['String']>;
  managerName?: InputMaybe<Scalars['String']>;
  managerPhone?: InputMaybe<Scalars['String']>;
  maxMonthlyCashbackAmount?: InputMaybe<Scalars['Int']>;
  merchantId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  representativeName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type MutationUnRegisterOtpArgs = {
  confirmCode: Scalars['String'];
};

export type MutationUpdateAdminGroupArgs = {
  adminId: Scalars['ID'];
  groupId: Scalars['ID'];
  operation: Scalars['Int'];
};

export type MutationUpdateApiKeyArgs = {
  expireAt: Scalars['Date'];
  id: Scalars['ID'];
};

export type MutationUpdateCashReceiptArgs = {
  id: Scalars['ID'];
  isCancel?: InputMaybe<Scalars['Boolean']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  receiptType?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateGroupAdminArgs = {
  adminId: Scalars['ID'];
  groupId: Scalars['ID'];
  operation?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateTagArgs = {
  status: Scalars['String'];
  tagId: Scalars['Int'];
  tagName: Scalars['String'];
};

export type MutationUpdateTagV2Args = {
  newTagName?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  tagName: Scalars['String'];
};

export type MutationUserOffsetDisposalArgs = {
  id: Scalars['ID'];
};

export type MutationUserUnregisterArgs = {
  id: Scalars['ID'];
};

export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
};

export type MutationUserWithdrawPointArgs = {
  accountKey: Scalars['String'];
  id: Scalars['ID'];
};

export type Notice = {
  __typename?: 'Notice';
  author?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  fixed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type NoticeList = {
  __typename?: 'NoticeList';
  list?: Maybe<Array<Maybe<Notice>>>;
  total?: Maybe<Scalars['Int']>;
};

export enum NotificationCategory {
  Boost = 'BOOST',
  Card = 'CARD',
  Chai = 'CHAI',
  DoveWallet = 'DOVE_WALLET',
}

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  category: NotificationCategory;
  code: Scalars['String'];
  createdAt: Scalars['Date'];
  delay: Scalars['Int'];
  deletedAt: Scalars['Date'];
  id: Scalars['ID'];
  isCommon: Scalars['Boolean'];
  isFcm: Scalars['Boolean'];
  isKakao: Scalars['Boolean'];
  isSms: Scalars['Boolean'];
  messages: Array<NotificationMessageEntity>;
  updatedAt: Scalars['Date'];
  variables: Scalars['JSON'];
};

export type NotificationInput = {
  category: NotificationCategory;
  code: Scalars['String'];
  delay?: InputMaybe<Scalars['Float']>;
  isCommon?: InputMaybe<Scalars['Boolean']>;
  isFcm?: InputMaybe<Scalars['Boolean']>;
  isKakao?: InputMaybe<Scalars['Boolean']>;
  isSms?: InputMaybe<Scalars['Boolean']>;
  variables: Scalars['JSON'];
};

export type NotificationMessageEntity = {
  __typename?: 'NotificationMessageEntity';
  buttons: Scalars['JSON'];
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  data: Scalars['JSON'];
  from?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAd?: Maybe<Scalars['Boolean']>;
  kakaoTitle?: Maybe<Scalars['String']>;
  notificationId: Scalars['ID'];
  scheme?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
  type: NotificationMessageType;
  updatedAt: Scalars['Date'];
};

export type NotificationMessageInput = {
  buttons?: InputMaybe<Scalars['JSON']>;
  content: Scalars['String'];
  data?: InputMaybe<Scalars['JSON']>;
  from?: InputMaybe<Scalars['String']>;
  isAd?: InputMaybe<Scalars['Boolean']>;
  kakaoTitle?: InputMaybe<Scalars['String']>;
  scheme?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateCode?: InputMaybe<Scalars['String']>;
  type: NotificationMessageType;
};

export enum NotificationMessageType {
  Common = 'COMMON',
  Fcm = 'FCM',
  Kakao = 'KAKAO',
  Sms = 'SMS',
}

export type NotificationWithMessageInput = {
  category: NotificationCategory;
  code: Scalars['String'];
  delay: Scalars['Int'];
  isCommon: Scalars['Boolean'];
  isFcm: Scalars['Boolean'];
  isKakao: Scalars['Boolean'];
  isSms: Scalars['Boolean'];
  messages: Array<NotificationMessageInput>;
  variables: Scalars['JSON'];
};

export type PgAccount = {
  __typename?: 'PGAccount';
  accountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isRepresentative?: Maybe<Scalars['Boolean']>;
};

export type PgAccountResult = {
  __typename?: 'PGAccountResult';
  accounts?: Maybe<Array<Maybe<PgAccount>>>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  billingAmount?: Maybe<Scalars['Float']>;
  canceledAmount?: Maybe<Scalars['Float']>;
  canceledBillingAmount?: Maybe<Scalars['Float']>;
  canceledCashAmount?: Maybe<Scalars['Float']>;
  canceledCashbackAmount?: Maybe<Scalars['Float']>;
  canceledDiscountAmount?: Maybe<Scalars['Float']>;
  canceledPointAmount?: Maybe<Scalars['Float']>;
  cashAmount?: Maybe<Scalars['Float']>;
  cashbackAmount?: Maybe<Scalars['Float']>;
  cashbackStatus?: Maybe<Scalars['String']>;
  cashbacks?: Maybe<Array<Maybe<Promotion>>>;
  chargingAmount?: Maybe<Scalars['Float']>;
  checkoutAmount?: Maybe<Scalars['Float']>;
  coupon?: Maybe<Coupon>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  idempotencyKey?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  merchantId?: Maybe<Scalars['ID']>;
  pointAmount?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  reportedBoost?: Maybe<BoostReport>;
  returnUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  usedBoost?: Maybe<Boost>;
  user?: Maybe<User>;
};

export type PaymentCategoryTag = {
  __typename?: 'PaymentCategoryTag';
  id: Scalars['Int'];
  name: Scalars['String'];
  paymentTags?: Maybe<Array<Maybe<PaymentTag>>>;
};

export type PaymentCategoryTagList = {
  __typename?: 'PaymentCategoryTagList';
  list?: Maybe<Array<Maybe<PaymentCategoryTag>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaymentList = {
  __typename?: 'PaymentList';
  list?: Maybe<Array<Maybe<PaymentWithOutUser>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaymentOutData = {
  __typename?: 'PaymentOutData';
  paymentId?: Maybe<Scalars['String']>;
};

export type PaymentTag = {
  __typename?: 'PaymentTag';
  condition: Scalars['JSON'];
  conditionText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PaymentTagList = {
  __typename?: 'PaymentTagList';
  list?: Maybe<Array<Maybe<PaymentTag>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaymentWithOutUser = {
  __typename?: 'PaymentWithOutUser';
  billingAmount?: Maybe<Scalars['Float']>;
  canceledAmount?: Maybe<Scalars['Float']>;
  canceledBillingAmount?: Maybe<Scalars['Float']>;
  canceledCashAmount?: Maybe<Scalars['Float']>;
  canceledCashbackAmount?: Maybe<Scalars['Float']>;
  canceledDiscountAmount?: Maybe<Scalars['Float']>;
  canceledPointAmount?: Maybe<Scalars['Float']>;
  cashAmount?: Maybe<Scalars['Float']>;
  cashbackAmount?: Maybe<Scalars['Float']>;
  cashbackStatus?: Maybe<Scalars['String']>;
  cashbacks?: Maybe<Array<Maybe<Promotion>>>;
  chargingAmount?: Maybe<Scalars['Float']>;
  checkoutAmount?: Maybe<Scalars['Float']>;
  coupon?: Maybe<Coupon>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  idempotencyKey?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  merchantId?: Maybe<Scalars['ID']>;
  pointAmount?: Maybe<Scalars['Float']>;
  raw?: Maybe<Scalars['JSON']>;
  returnUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PeriodCapCondition = {
  __typename?: 'PeriodCapCondition';
  count: Scalars['Int'];
  period: Scalars['Int'];
};

export type PeriodCapInput = {
  count: Scalars['Int'];
  period: Scalars['Int'];
};

export type PeriodCapsPerUserRule = {
  __typename?: 'PeriodCapsPerUserRule';
  count?: Maybe<Scalars['Int']>;
  range?: Maybe<Array<Maybe<PeriodCapsPerUserRuleRange>>>;
  type: Scalars['String'];
};

export type PeriodCapsPerUserRuleRange = {
  __typename?: 'PeriodCapsPerUserRuleRange';
  count: Scalars['Int'];
  period: Scalars['Int'];
};

export type PeriodCapsPerUserRuleRangeInput = {
  count: Scalars['Int'];
  period: Scalars['Int'];
};

export type Policy = {
  __typename?: 'Policy';
  createdAt: Scalars['Date'];
  data?: Maybe<PolicyData>;
  description: Scalars['String'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  startAt: Scalars['Date'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type PolicyCharge = {
  __typename?: 'PolicyCharge';
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxBalance: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAmount: Scalars['Float'];
  minUnit: Scalars['Int'];
};

export type PolicyData = PolicyCharge | PolicyPgFee | PolicyPayment | PolicyWithdrawal;

export type PolicyList = {
  __typename?: 'PolicyList';
  list?: Maybe<Array<Maybe<Policy>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PolicyPgFee = {
  __typename?: 'PolicyPGFee';
  fee: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type PolicyPayment = {
  __typename?: 'PolicyPayment';
  bank?: Maybe<Scalars['String']>;
  constraint?: Maybe<Scalars['String']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAmountDay: Scalars['Float'];
  maxAmountMonth: Scalars['Float'];
  maxAmountOnce: Scalars['Float'];
  maxCount: Scalars['Int'];
  minAge?: Maybe<Scalars['Int']>;
  minAmount: Scalars['Float'];
  minUnit: Scalars['Int'];
};

export type PolicyWithdrawal = {
  __typename?: 'PolicyWithdrawal';
  fee: Scalars['String'];
  maxAmount: Scalars['Float'];
  maxCountPerDay: Scalars['Int'];
  minAmount: Scalars['Float'];
};

export type ProbabilityInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
  probability: Scalars['Float'];
};

export type Promotion = {
  __typename?: 'Promotion';
  benefitType: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  couponCounts?: Maybe<CouponCounts>;
  couponDueDate?: Maybe<Scalars['Date']>;
  couponStartDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  discountSplitConditions?: Maybe<Array<Maybe<DiscountSplitCondition>>>;
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isBulkIssuable?: Maybe<Scalars['Boolean']>;
  isMerchantLifetimePromotion?: Maybe<Scalars['Boolean']>;
  isSelectable?: Maybe<Scalars['Boolean']>;
  isUsableOnAutoCharge?: Maybe<Scalars['Boolean']>;
  isUsableOnFirstPurchase?: Maybe<Scalars['Boolean']>;
  issueType?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['Int']>;
  maxDiscountAmount?: Maybe<Scalars['Int']>;
  maxPerPolicy?: Maybe<Scalars['Int']>;
  maxPerUser?: Maybe<Scalars['Int']>;
  merchants?: Maybe<Array<Maybe<Merchant>>>;
  pid?: Maybe<Array<Maybe<Scalars['String']>>>;
  policyType: Scalars['String'];
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  subMerchants?: Maybe<Array<Maybe<PromotionSubMerchant>>>;
  title: Scalars['String'];
  ttl?: Maybe<Scalars['Int']>;
  ttlType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  used?: Maybe<Scalars['Int']>;
};

export type PromotionAirdrop = {
  __typename?: 'PromotionAirdrop';
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  issueAt: Scalars['Date'];
  manager: Scalars['String'];
  promotion: Promotion;
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PromotionAirdropList = {
  __typename?: 'PromotionAirdropList';
  list?: Maybe<Array<Maybe<PromotionAirdrop>>>;
};

export type PromotionGroup = {
  __typename?: 'PromotionGroup';
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  detailTitle: Scalars['String'];
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  landingUrl?: Maybe<Scalars['String']>;
  merchant: Merchant;
  promotions?: Maybe<Scalars['JSON']>;
  sortedIndex?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PromotionGroupList = {
  __typename?: 'PromotionGroupList';
  list?: Maybe<Array<Maybe<PromotionGroup>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PromotionInput = {
  discount: Scalars['String'];
  maxDiscountAmount: Scalars['Int'];
  title: Scalars['String'];
};

export type PromotionList = {
  __typename?: 'PromotionList';
  list?: Maybe<Array<Maybe<Promotion>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PromotionSubMerchant = {
  __typename?: 'PromotionSubMerchant';
  promotionPolicy?: Maybe<Promotion>;
  subMerchant?: Maybe<SubMerchant>;
};

export type Query = {
  __typename?: 'Query';
  _Empty?: Maybe<Scalars['String']>;
  agreement?: Maybe<Agreement>;
  agreementList?: Maybe<AgreementList>;
  availableBoostList?: Maybe<BoostList>;
  b2bUser?: Maybe<B2BUser>;
  b2bUserList?: Maybe<B2BUserList>;
  bank?: Maybe<Bank>;
  bankList?: Maybe<BankList>;
  banner?: Maybe<Banner>;
  bannerGroup?: Maybe<BannerGroup>;
  bannerGroupList?: Maybe<BannerGroupList>;
  bannerList?: Maybe<BannerList>;
  boltDrawPromotion?: Maybe<BoltDrawPromotion>;
  boltDrawPromotionImageList?: Maybe<BoltDrawPromotionImageList>;
  boltDrawPromotionList?: Maybe<BoltDrawPromotionList>;
  boltExclusionRule?: Maybe<BoltExclusionRule>;
  boltExclusionRuleList?: Maybe<BoltExclusionRuleList>;
  boltHistoryList?: Maybe<BoltHistoryList>;
  boltPolicy?: Maybe<BoltPolicy>;
  boltPolicyList?: Maybe<BoltPolicyList>;
  boostBudget?: Maybe<BoostBudget>;
  boostBudgetList?: Maybe<BoostBudgetList>;
  boostBudgetStats?: Maybe<BoostBudgetStats>;
  boostCampaign?: Maybe<BoostCampaign>;
  boostCampaignAdSpend?: Maybe<BoostCampaignAdSpend>;
  boostCampaignList?: Maybe<BoostCampaignList>;
  boostCategory?: Maybe<BoostCategory>;
  boostCategoryBoostList?: Maybe<BoostPromotionList>;
  boostCategoryList?: Maybe<BoostCategoryList>;
  boostExclusionRule?: Maybe<BoostExclusionRule>;
  boostExclusionRuleList?: Maybe<BoostExclusionRuleList>;
  boostImportant?: Maybe<Scalars['JSON']>;
  boostImportantList?: Maybe<BoostImportantList>;
  boostList?: Maybe<BoostList>;
  boostMissionPolicy?: Maybe<BoostMissionPolicy>;
  boostMissionPolicyList?: Maybe<BoostMissionPolicyList>;
  boostPromotion?: Maybe<BoostPromotion>;
  boostPromotionList?: Maybe<BoostPromotionList>;
  boostTargetCondition?: Maybe<BoostTargetCondition>;
  boostTargetConditionList?: Maybe<BoostTargetConditionList>;
  boostUp?: Maybe<BoostUp>;
  boostUpDefault?: Maybe<BoostUp>;
  boostUpImportant?: Maybe<Scalars['String']>;
  boostUpList?: Maybe<BoostUpList>;
  boostUpUserHistory?: Maybe<BoostUpUserHistoryList>;
  brand?: Maybe<Brand>;
  brandContentList?: Maybe<BrandContentList>;
  brandList?: Maybe<BrandList>;
  cardProduct?: Maybe<CardProduct>;
  cardProductList?: Maybe<CardProductList>;
  chartData?: Maybe<ChartData>;
  checkTagJob?: Maybe<TagJob>;
  contractList?: Maybe<ContractList>;
  couponList?: Maybe<CouponList>;
  dummyContractList?: Maybe<ContractList>;
  dummyLuckyPromotionList?: Maybe<LuckyPromotionList>;
  dummyMerchantList?: Maybe<MerchantList>;
  dummyPaymentList?: Maybe<PaymentList>;
  dummyPolicyList?: Maybe<PolicyList>;
  dummyPromotionList?: Maybe<PromotionList>;
  dummySettlement?: Maybe<Settlement>;
  dummySettlementList?: Maybe<Array<Maybe<Settlement>>>;
  exchangePropertyRule?: Maybe<ExchangePropertyRule>;
  exchangePropertyRuleList?: Maybe<ExchangePropertyRuleList>;
  existAdminIds?: Maybe<Array<Scalars['String']>>;
  faq?: Maybe<Faq>;
  faqList?: Maybe<FaqList>;
  getAccessMenus?: Maybe<Array<Maybe<Menu>>>;
  getActions?: Maybe<Array<Maybe<Action>>>;
  getAdmins?: Maybe<AdminList>;
  getAllMenuList?: Maybe<Array<Maybe<Menu>>>;
  getGroupActions?: Maybe<Group>;
  getGroupMenus?: Maybe<Group>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  getKVoucherStatus?: Maybe<Scalars['Boolean']>;
  getMenuActions?: Maybe<Array<Maybe<Menu>>>;
  getMenuMapToRouter?: Maybe<Scalars['String']>;
  getMetroCardList?: Maybe<IMetroCardResponse>;
  getMetroHistory?: Maybe<IMetroHistoryResponse>;
  getMyAdmin?: Maybe<Admin>;
  getNotification?: Maybe<NotificationEntity>;
  getNotifications: Array<NotificationEntity>;
  getOTPKey?: Maybe<TempOtpKey>;
  getPGAccounts?: Maybe<PgAccountResult>;
  getTag?: Maybe<Tag>;
  getTagById?: Maybe<Tag>;
  getTagList?: Maybe<TagList>;
  getTagListByPolicyMapping?: Maybe<Array<Maybe<Tag>>>;
  getTagListByTargetMapping?: Maybe<Array<Maybe<Tag>>>;
  getTagPolicyList?: Maybe<TagPolicyMappingList>;
  getTagTargetList?: Maybe<TagTargetMappingList>;
  getTagV2List?: Maybe<TagV2List>;
  getTaxInvoice?: Maybe<Array<Maybe<SettlementTaxInvoice>>>;
  getVoucherBalance?: Maybe<VoucherBalance>;
  i18nList?: Maybe<Array<Maybe<I18n>>>;
  luckyPromotion?: Maybe<LuckyPromotion>;
  luckyPromotionList?: Maybe<LuckyPromotionList>;
  maskedUser?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  merchantList?: Maybe<MerchantList>;
  monohaBoostPromotionList?: Maybe<MonohaBoostPromotionList>;
  notice?: Maybe<Notice>;
  noticeList?: Maybe<NoticeList>;
  payment?: Maybe<Payment>;
  paymentCategoryTag?: Maybe<PaymentCategoryTag>;
  paymentCategoryTagList?: Maybe<PaymentCategoryTagList>;
  paymentList?: Maybe<PaymentList>;
  paymentTag?: Maybe<PaymentTag>;
  paymentTagList?: Maybe<PaymentTagList>;
  paymentsMerchantName?: Maybe<Array<Maybe<MerchantNames>>>;
  policy?: Maybe<Policy>;
  policyList?: Maybe<PolicyList>;
  promotion?: Maybe<Promotion>;
  promotionAirdropList?: Maybe<PromotionAirdropList>;
  promotionGroup?: Maybe<PromotionGroup>;
  promotionGroupList?: Maybe<PromotionGroupList>;
  promotionList?: Maybe<PromotionList>;
  settlement?: Maybe<Settlement>;
  settlementList?: Maybe<Array<Maybe<Settlement>>>;
  settlementPaymentList?: Maybe<PaymentList>;
  settlementPreview?: Maybe<Array<Maybe<SettlementPreview>>>;
  settlementTransfer?: Maybe<Array<Maybe<SettlementTransfer>>>;
  settlementWithBankInfo?: Maybe<Array<Maybe<SettlementBankFormat>>>;
  settlements?: Maybe<Settlements>;
  subMerchant?: Maybe<SubMerchant>;
  subMerchantList?: Maybe<SubMerchantList>;
  transaction?: Maybe<Transaction>;
  transactionList?: Maybe<TransactionList>;
  translation?: Maybe<Scalars['String']>;
  unMaskedUser?: Maybe<User>;
  userByPhoneNumber?: Maybe<UserWithCard>;
  userContactInvitationList?: Maybe<ContactInvitationList>;
  userDelayedCashbackList?: Maybe<DelayedCashbackList>;
  userHistory?: Maybe<Array<Maybe<User>>>;
  userList?: Maybe<UserList>;
  userTopupList?: Maybe<TopupList>;
  userTransactionList?: Maybe<TransactionList>;
};

export type QueryAgreementArgs = {
  id: Scalars['ID'];
};

export type QueryAvailableBoostListArgs = {
  paymentId?: InputMaybe<Scalars['String']>;
};

export type QueryB2bUserArgs = {
  id: Scalars['ID'];
};

export type QueryB2bUserListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBankArgs = {
  id: Scalars['Int'];
};

export type QueryBankListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBannerArgs = {
  id: Scalars['ID'];
};

export type QueryBannerGroupArgs = {
  id: Scalars['ID'];
};

export type QueryBannerGroupListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBannerListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoltDrawPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryBoltDrawPromotionImageListArgs = {
  boltDrawPromotionId: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryBoltDrawPromotionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoltExclusionRuleArgs = {
  id: Scalars['ID'];
};

export type QueryBoltExclusionRuleListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoltHistoryListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoltPolicyArgs = {
  id: Scalars['ID'];
};

export type QueryBoltPolicyListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostBudgetArgs = {
  id: Scalars['Int'];
};

export type QueryBoostBudgetListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostBudgetStatsArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type QueryBoostCampaignArgs = {
  id: Scalars['Int'];
};

export type QueryBoostCampaignAdSpendArgs = {
  boostCampaignId: Scalars['Int'];
};

export type QueryBoostCampaignListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryBoostCategoryBoostListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostCategoryListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostExclusionRuleArgs = {
  id: Scalars['ID'];
};

export type QueryBoostExclusionRuleListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostImportantArgs = {
  sortKey: Scalars['String'];
  table: Scalars['String'];
};

export type QueryBoostImportantListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  table: Scalars['String'];
};

export type QueryBoostListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostMissionPolicyArgs = {
  id: Scalars['Int'];
};

export type QueryBoostMissionPolicyListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostPromotionArgs = {
  id: Scalars['Int'];
};

export type QueryBoostPromotionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostTargetConditionArgs = {
  id: Scalars['Int'];
};

export type QueryBoostTargetConditionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostUpArgs = {
  id: Scalars['Int'];
};

export type QueryBoostUpDefaultArgs = {
  targetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryBoostUpListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBoostUpUserHistoryArgs = {
  filter: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryBrandArgs = {
  id: Scalars['Int'];
};

export type QueryBrandContentListArgs = {
  id: Scalars['Int'];
  visible?: InputMaybe<Scalars['String']>;
};

export type QueryBrandListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryCardProductArgs = {
  id: Scalars['Int'];
};

export type QueryCardProductListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryChartDataArgs = {
  endAt: Scalars['Date'];
  startAt: Scalars['Date'];
  timeIntervals: ChartTimeIntervals;
};

export type QueryCheckTagJobArgs = {
  jobId: Scalars['String'];
};

export type QueryContractListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryCouponListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryExchangePropertyRuleArgs = {
  id: Scalars['ID'];
};

export type QueryExchangePropertyRuleListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryExistAdminIdsArgs = {
  adminIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryFaqArgs = {
  id: Scalars['ID'];
};

export type QueryFaqListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetActionsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetAdminsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetGroupActionsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetGroupMenusArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetGroupsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMenuActionsArgs = {
  menuId?: InputMaybe<Scalars['String']>;
};

export type QueryGetMetroCardListArgs = {
  id: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMetroHistoryArgs = {
  category?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type QueryGetNotificationArgs = {
  notificationId: Scalars['ID'];
};

export type QueryGetNotificationsArgs = {
  category?: InputMaybe<NotificationCategory>;
};

export type QueryGetPgAccountsArgs = {
  sequenceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryGetTagArgs = {
  tagName: Scalars['String'];
};

export type QueryGetTagByIdArgs = {
  id: Scalars['Int'];
};

export type QueryGetTagListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTagListByPolicyMappingArgs = {
  mappingId: Scalars['String'];
  mappingType: Scalars['String'];
};

export type QueryGetTagListByTargetMappingArgs = {
  mappingId: Scalars['String'];
  mappingType: Scalars['String'];
};

export type QueryGetTagPolicyListArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  tagName: Scalars['String'];
};

export type QueryGetTagTargetListArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  tagName: Scalars['String'];
};

export type QueryGetTagV2ListArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  tagName?: InputMaybe<Scalars['String']>;
};

export type QueryGetTaxInvoiceArgs = {
  date: Scalars['Date'];
};

export type QueryGetVoucherBalanceArgs = {
  id: Scalars['ID'];
};

export type QueryI18nListArgs = {
  columnName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  tableName: Scalars['String'];
  targetId: Scalars['String'];
};

export type QueryLuckyPromotionArgs = {
  id: Scalars['ID'];
};

export type QueryLuckyPromotionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryMaskedUserArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryMonohaBoostPromotionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryNoticeArgs = {
  id: Scalars['ID'];
};

export type QueryNoticeListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentCategoryTagArgs = {
  id: Scalars['Int'];
};

export type QueryPaymentCategoryTagListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentTagArgs = {
  id: Scalars['Int'];
};

export type QueryPaymentTagListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentsMerchantNameArgs = {
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryPolicyArgs = {
  id: Scalars['ID'];
};

export type QueryPolicyListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPromotionArgs = {
  id: Scalars['ID'];
};

export type QueryPromotionAirdropListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPromotionGroupArgs = {
  id: Scalars['ID'];
};

export type QueryPromotionGroupListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryPromotionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerySettlementArgs = {
  filter?: InputMaybe<Scalars['String']>;
};

export type QuerySettlementListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerySettlementPaymentListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerySettlementTransferArgs = {
  filter?: InputMaybe<Scalars['String']>;
};

export type QuerySettlementWithBankInfoArgs = {
  date: Scalars['Date'];
};

export type QuerySettlementsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerySubMerchantArgs = {
  id: Scalars['Int'];
};

export type QuerySubMerchantListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryTransactionArgs = {
  id: Scalars['ID'];
};

export type QueryTransactionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryTranslationArgs = {
  format?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  query: Scalars['String'];
};

export type QueryUnMaskedUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserByPhoneNumberArgs = {
  phone: Scalars['String'];
};

export type QueryUserContactInvitationListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryUserDelayedCashbackListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryUserHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryUserListArgs = {
  fullname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type QueryUserTopupListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryUserTransactionListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type RequiredActions = {
  __typename?: 'RequiredActions';
  blocked?: Maybe<Scalars['Int']>;
};

export type SelectPreApplicationResult = {
  __typename?: 'SelectPreApplicationResult';
  failedUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  createdAt: Scalars['Date'];
  feeAmount?: Maybe<Scalars['Int']>;
  feeTaxAmount?: Maybe<Scalars['Int']>;
  history?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  merchantId: Scalars['ID'];
  minusAmount?: Maybe<Scalars['Float']>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  plusAmount?: Maybe<Scalars['Float']>;
  referenceDate?: Maybe<Scalars['Date']>;
  refundCount?: Maybe<Scalars['Int']>;
  settlementDate?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  transactionCount?: Maybe<Scalars['Int']>;
  transfer?: Maybe<SettlementTransfer>;
};

export type SettlementAdjustment = {
  __typename?: 'SettlementAdjustment';
  amount: Scalars['Float'];
  author: Scalars['String'];
  memo: Scalars['String'];
};

export type SettlementBankFormat = {
  __typename?: 'SettlementBankFormat';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  feeAndTaxAmount?: Maybe<Scalars['Int']>;
  merchantId: Scalars['ID'];
  reserveAmount?: Maybe<Scalars['Int']>;
};

export type SettlementPreview = {
  __typename?: 'SettlementPreview';
  amount?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankHolder?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  feeAmount?: Maybe<Scalars['Float']>;
  merchantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SettlementTaxInvoice = {
  __typename?: 'SettlementTaxInvoice';
  address: Scalars['String'];
  businessCategory?: Maybe<Scalars['String']>;
  businessNumber: Scalars['String'];
  businessType?: Maybe<Scalars['String']>;
  endOfMonth: Scalars['String'];
  feeAmount?: Maybe<Scalars['Float']>;
  feeTaxAmount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  representativeName: Scalars['String'];
  settlementEmail?: Maybe<Scalars['String']>;
  settlementSubEmail?: Maybe<Scalars['String']>;
};

export type SettlementTransfer = {
  __typename?: 'SettlementTransfer';
  amount: Scalars['Float'];
  author: Scalars['String'];
  createdAt: Scalars['Date'];
  finishedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  merchantId: Scalars['String'];
  settlementAdjustment?: Maybe<SettlementAdjustment>;
  status: Scalars['String'];
};

export type SettlementTransferInput = {
  amount: Scalars['Int'];
  isManual: Scalars['Boolean'];
  merchantId: Scalars['String'];
};

export type Settlements = {
  __typename?: 'Settlements';
  list?: Maybe<Array<Maybe<Settlement>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SubMerchant = {
  __typename?: 'SubMerchant';
  address?: Maybe<Scalars['String']>;
  businessNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  customerServicePhone?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  managerEmail?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  managerPhone?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  name: Scalars['String'];
  representativeName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type SubMerchantList = {
  __typename?: 'SubMerchantList';
  list?: Maybe<Array<Maybe<SubMerchant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SubPromotion = {
  __typename?: 'SubPromotion';
  boltPrice: Scalars['Int'];
  discount: Scalars['String'];
  maxDiscountAmount?: Maybe<Scalars['Int']>;
  promotionId?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type SubPromotionInput = {
  boltPrice: Scalars['Int'];
  discount: Scalars['String'];
  maxDiscountAmount?: InputMaybe<Scalars['Int']>;
  promotionId?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _Empty?: Maybe<Scalars['String']>;
  userAdded: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  category?: Maybe<Scalars['String']>;
  dateHistory?: Maybe<DateHistory>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TagJob = {
  __typename?: 'TagJob';
  jobId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TagList = {
  __typename?: 'TagList';
  list?: Maybe<Array<Maybe<Tag>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TagPolicyMapping = {
  __typename?: 'TagPolicyMapping';
  dateHistory?: Maybe<DeleteHistory>;
  id?: Maybe<Scalars['ID']>;
  policyId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
  type?: Maybe<Scalars['String']>;
};

export type TagPolicyMappingList = {
  __typename?: 'TagPolicyMappingList';
  list?: Maybe<Array<Maybe<TagPolicyMapping>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TagTargetMapping = {
  __typename?: 'TagTargetMapping';
  dateHistory?: Maybe<DeleteHistory>;
  id?: Maybe<Scalars['ID']>;
  tag?: Maybe<Tag>;
  targetId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TagTargetMappingList = {
  __typename?: 'TagTargetMappingList';
  list?: Maybe<Array<Maybe<TagTargetMapping>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TagV2List = {
  __typename?: 'TagV2List';
  list?: Maybe<Array<Maybe<Scalars['String']>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Topup = {
  __typename?: 'Topup';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TopupList = {
  __typename?: 'TopupList';
  list?: Maybe<Array<Maybe<Topup>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float'];
  balance: Scalars['Float'];
  createdAt?: Maybe<Scalars['Date']>;
  currency: Scalars['String'];
  data?: Maybe<TransactionData>;
  delta?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  ledger?: Maybe<Ledger>;
  type: Scalars['String'];
};

export type TransactionData = ChargeInData | PaymentOutData;

export type TransactionList = {
  __typename?: 'TransactionList';
  list?: Maybe<Array<Maybe<Transaction>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UploadedContent = {
  __typename?: 'UploadedContent';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UploadedImage = {
  __typename?: 'UploadedImage';
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  autoCharge?: Maybe<Scalars['JSON']>;
  balance: Scalars['Float'];
  birthday?: Maybe<Scalars['Date']>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  fullname: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitationCount?: Maybe<Scalars['Int']>;
  isVictim?: Maybe<Scalars['Boolean']>;
  koreaSpec?: Maybe<KoreaSpec>;
  ledger?: Maybe<Ledger>;
  monthlyCashback?: Maybe<Array<Maybe<MonthlyCashback>>>;
  phone: Scalars['String'];
  requiredActions?: Maybe<RequiredActions>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserList = {
  __typename?: 'UserList';
  list?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UserWithCard = {
  __typename?: 'UserWithCard';
  accountSuffix?: Maybe<Scalars['String']>;
  cardUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  phone: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type VoucherBalance = {
  __typename?: 'VoucherBalance';
  amount?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type MonthlyCashback = {
  __typename?: 'monthlyCashback';
  date?: Maybe<Scalars['Date']>;
  monthlyCashbackAmount?: Maybe<Scalars['Int']>;
};

export type TempOtpKey = {
  __typename?: 'tempOTPKey';
  tempKey?: Maybe<Scalars['String']>;
};

export type SettlementManualMutationVariables = Exact<{
  otp: Scalars['String'];
  input: Array<SettlementTransferInput> | SettlementTransferInput;
}>;

export type SettlementManualMutation = { __typename?: 'Mutation'; createSettlementTransfers?: boolean | null };

export const SettlementManualDocument = gql`
  mutation SettlementManual($otp: String!, $input: [SettlementTransferInput!]!) {
    createSettlementTransfers(otp: $otp, input: $input)
  }
`;
export type SettlementManualMutationFn = Apollo.MutationFunction<
  SettlementManualMutation,
  SettlementManualMutationVariables
>;

/**
 * __useSettlementManualMutation__
 *
 * To run a mutation, you first call `useSettlementManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettlementManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settlementManualMutation, { data, loading, error }] = useSettlementManualMutation({
 *   variables: {
 *      otp: // value for 'otp'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettlementManualMutation(
  baseOptions?: Apollo.MutationHookOptions<SettlementManualMutation, SettlementManualMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SettlementManualMutation, SettlementManualMutationVariables>(
    SettlementManualDocument,
    options
  );
}
export type SettlementManualMutationHookResult = ReturnType<typeof useSettlementManualMutation>;
export type SettlementManualMutationResult = Apollo.MutationResult<SettlementManualMutation>;
export type SettlementManualMutationOptions = Apollo.BaseMutationOptions<
  SettlementManualMutation,
  SettlementManualMutationVariables
>;
