import { DataGrid, GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { ISettlement, settlementsQuery } from '../../api/settlementGql';
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ChaiTextForm } from '../../common';
import { Controller, useForm } from 'react-hook-form';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useState } from 'react';

export function SettlementHistory() {
  const pageSize = 100;

  const { data, loading, refetch, fetchMore } = useQuery<{ settlements: { total: number; list: ISettlement[] } }>(
    settlementsQuery,
    {
      variables: {
        skip: 0,
        pageSize,
      },
    }
  );
  const { register, control, handleSubmit } = useForm();
  const [page, setPage] = useState(0);
  const dateGetter = (params: GridValueGetterParams) => format(new Date(params.value), 'yyyy-MM-dd');

  const columns: GridColumns = [
    { field: 'referenceDate', type: 'date', valueGetter: dateGetter },
    { field: 'settlementDate', type: 'date', valueGetter: dateGetter },
    { field: 'merchantId' },
    { field: 'plusAmount' },
    { field: 'minusAmount' },
    { field: 'name', valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.merchant.name },
    {
      field: 'displayName',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.merchant.displayName,
    },
    { field: 'status' },
    { field: 'createdAt', type: 'date', valueGetter: dateGetter },
    {
      field: 'businessNumber',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.merchant.businessNumber,
    },
    { field: 'transactionCount' },
    {
      field: 'totalFee',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.feeAmount + params.row.feeTaxAmount,
    },
    { field: 'feeAmount' },
    { field: 'feeTaxAmount' },
    {
      field: 'totalAmount',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) =>
        params.row.plusAmount + params.row.minusAmount - params.row.feeAmount - params.row.feeTaxAmount,
    },
    {
      field: 'transfer.finishedAt',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.transfer?.finishedAt,
    },
    {
      field: 'transfer.amount',
      type: 'number',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.transfer?.amount,
    },
    {
      field: 'transfer.status',
      type: 'string',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.transfer?.status,
    },
    {
      field: 'transfer.author',
      type: 'string',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.transfer?.author,
    },
    {
      field: 'transfer.settlementAdjustment.amount',
      type: 'number',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) =>
        params.row.transfer?.settlementAdjustment?.amount,
    },
    {
      field: 'transfer.settlementAdjustment.memo',
      type: 'string',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) => params.row.transfer?.settlementAdjustment?.memo,
    },
    {
      field: 'transfer.settlementAdjustment.author',
      type: 'string',
      valueGetter: (params: GridValueGetterParams<any, ISettlement>) =>
        params.row.transfer?.settlementAdjustment?.author,
    },
  ];
  const rows: readonly { [key: string]: any }[] = data?.settlements?.list ?? [];
  const total = data?.settlements?.total ?? 0;

  function onSubmitSearchSettlementList(data: any) {
    refetch({
      filter: JSON.stringify({
        merchantId: data.merchantId,
        dateType: data.dateType,
        from: startOfDay(new Date(data.startDate)),
        to: endOfDay(new Date(data.endDate)),
      }),
      skip: 0,
      pageSize: 100,
    });
    setPage(0);
  }

  function onPageChange(newPage: number) {
    if (rows.length > newPage * pageSize) {
    } else {
      fetchMore<{ settlements: { total: number; list: ISettlement[] } }, any>({
        variables: {
          skip: newPage * pageSize,
          pageSize: pageSize,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            settlements: {
              total: fetchMoreResult.settlements.total,
              list: [...prev.settlements.list, ...fetchMoreResult.settlements.list],
            },
          };
        },
      }).then(() => {});
    }
    setPage(newPage);
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <div>
        <form onSubmit={handleSubmit(onSubmitSearchSettlementList)}>
          <Box display="flex">
            <div>
              <ChaiTextForm register={register} name="merchantId" label="merchant id" />
            </div>
            <Controller
              name="dateType"
              control={control}
              defaultValue="settlement"
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup value={value} onChange={onChange}>
                  <ToggleButton value="settlement">정산일자</ToggleButton>
                  <ToggleButton value="payment">결제일자</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
            <Controller
              name="startDate"
              control={control}
              defaultValue={format(new Date(), 'yyyy-MM-dd')}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="date"
                  label="~부터"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              defaultValue={format(new Date(), 'yyyy-MM-dd')}
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="date"
                  label="~까지"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Button variant="contained" onClick={handleSubmit(onSubmitSearchSettlementList)}>
              검색
            </Button>
          </Box>
        </form>
      </div>
      <Box flex="1">
        <DataGrid
          page={page}
          columns={columns}
          rows={rows.map(row => ({ ...row, id: row.id.toString() })).slice(page * pageSize, (page + 1) * pageSize)}
          onPageChange={onPageChange}
          rowCount={total}
          loading={loading}
          pagination
          paginationMode="server"
        />
      </Box>
    </Box>
  );
}
