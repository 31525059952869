import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form';
import { ChaiTextForm } from '../../common';
import { SettlementTransferInput, useSettlementManualMutation } from '../../generated/graphql';

function DialogOTP(props: { isOpen: boolean; onSubmit: (data: { otp: string }) => void; handleClose: () => void }) {
  // @duplicate settlementList.tsx
  const { isOpen, onSubmit, handleClose } = props;
  const { register, handleSubmit } = useForm<{ otp: string }>();

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>수기 이체</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={20}>
              <ChaiTextForm register={register} required name="otp" label="otp" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            완료
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function DialogAddSettlementManualTransfer(props: {
  isOpen: boolean;
  onSubmit: (data: SettlementTransferInput) => void;
  handleClose: () => void;
}) {
  const { isOpen, onSubmit, handleClose } = props;
  const { register, handleSubmit, reset } = useForm<SettlementTransferInput>();

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>수기 이체 추가</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={20}>
              <ChaiTextForm register={register} required name="merchantId" label="merchantId" />
              <ChaiTextForm register={register} required type="number" name="amount" label="amount" />
              {/*<ChaiTextForm register={register} name="memo" label="memo" />*/}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            완료
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function SettlementManual() {
  const [settlementManualMutation] = useSettlementManualMutation();

  const manualFileInputRef = useRef<HTMLInputElement>(null);
  const [transferList, setTransferList] = useState<SettlementTransferInput[]>([]);
  const [isOpenDialogAddSettlementManualTransfer, setIsOpenDialogAddSettlementManualTransfer] = useState(false);
  const [isOpenDialogOTP, setIsOpenDialogOTP] = useState<boolean>(false);

  const columns: GridColumns = [
    { field: 'merchantId', type: 'string' },
    { field: 'amount', type: 'number' },
    { field: 'memo', type: 'string' },
  ];

  const rows: readonly { [key: string]: any }[] = transferList.map((data, key) => ({ ...data, id: key }));

  const openDialogAddSettlementManualTransfer = () => setIsOpenDialogAddSettlementManualTransfer(true);
  const closeDialogAddSettlementManualTransfer = () => setIsOpenDialogAddSettlementManualTransfer(false);

  const openDialogOTP = () => setIsOpenDialogOTP(true);
  const closeDialogOTP = () => setIsOpenDialogOTP(false);

  function onSubmitAddSettlementManualTransfer(data: SettlementTransferInput) {
    setTransferList([...transferList, data]);
    closeDialogAddSettlementManualTransfer();
  }

  function onChangeManualFile(e: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();

    reader.onload = evt => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json<SettlementTransferInput>(ws);
      /* Update state */
      setTransferList(data);
    };

    reader.readAsBinaryString(e.target.files![0]);
  }

  function onSubmitSettlementManual({ otp }: { otp: string }) {
    settlementManualMutation({
      variables: {
        otp,
        input: transferList.map(transfer => {
          transfer.isManual = true;
          return transfer;
        }),
      },
    }).then(result => {
      if (result === true) {
        window.location.reload();
      }
      closeDialogOTP();
    });
  }

  return (
    <Box flex="1" display="flex" flexDirection="column" gap={1}>
      <DialogOTP isOpen={isOpenDialogOTP} onSubmit={onSubmitSettlementManual} handleClose={closeDialogOTP} />

      <DialogAddSettlementManualTransfer
        isOpen={isOpenDialogAddSettlementManualTransfer}
        onSubmit={onSubmitAddSettlementManualTransfer}
        handleClose={closeDialogAddSettlementManualTransfer}
      />
      <input
        type="file"
        accept={
          // xlsx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        ref={manualFileInputRef}
        onChange={onChangeManualFile}
        style={{ display: 'none' }}
      />
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={1}>
          <Button variant="contained" onClick={() => manualFileInputRef.current?.click()}>
            업로드
          </Button>
          <Button variant="contained" onClick={openDialogAddSettlementManualTransfer}>
            추가
          </Button>
        </Box>
        <Box>
          <Button variant="contained" onClick={() => openDialogOTP()}>
            완료
          </Button>
        </Box>
      </Box>
      <DataGrid columns={columns} rows={rows} hideFooter />
    </Box>
  );
}
