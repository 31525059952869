import gql from 'graphql-tag';
import { IAdjustmentData } from '../page/Settlement/Settlement';

export const settlementPreviewQuery = gql`
  query settlementPreview {
    settlementPreview {
      merchantId
      name
      displayName
      amount
      feeAmount
      balance
      bankCode
      bankAccount
      bankHolder
      __typename
    }
  }
`;

export interface ISettlementAdjustment {
  merchantId: string;
  amount: number;
  memo: string;
  author?: string;
}

export interface ISettlementTransfer {
  id: number;
  createdAt: Date;
  finishedAt?: Date;
  amount: number;
  status: string;
  author: string;
  merchantId: string;
  settlementAdjustment?: ISettlementAdjustment;
}

export interface ISettlement {
  id: string;
  createdAt: Date;
  plusAmount: number;
  minusAmount: number;
  feeAmount: number;
  feeTaxAmount: number;
  status: String;
  settlementDate: Date;
  referenceDate: Date;
  history: JSON;
  transactionCount: number;
  refundCount: number;
  merchantId: string;
  merchant: any;
  payments: any;
  transfer?: ISettlementTransfer;
}

export const settlementsQuery = gql`
  query settlements($filter: String, $skip: Int, $pageSize: Int) {
    settlements(filter: $filter, skip: $skip, pageSize: $pageSize) {
      total
      list {
        id
        createdAt
        plusAmount
        minusAmount
        feeAmount
        feeTaxAmount
        status
        settlementDate
        referenceDate
        history
        transactionCount
        refundCount
        merchantId
        merchant {
          name
          displayName
        }
      transfer {
          id
          createdAt
          finishedAt
          amount
          status
          author
          settlementAdjustment {
            amount
            memo
            author
          }
        }}
    }
  }
`;

export interface ISettlementUpdateArgs {
  merchantIds: string[];
  otp?: string;
  adjustmentList?: IAdjustmentData[];
  transferException?: string;
}

export const settlementUpdateMutation = gql`
  mutation settlementUpdate(
    $merchantIds: [String]!
    $otp: String
    $adjustmentList: [Adjustment]
    $transferException: String
  ) {
    settlementUpdate(
      merchantIds: $merchantIds
      otp: $otp
      adjustmentList: $adjustmentList
      transferException: $transferException
    )
  }
`;
